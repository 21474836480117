import { Link } from 'react-router-dom';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { UnsupportedIconWrapper } from 'shared/styles/components/Maintenance';
import { TableCellCheckboxWrapper } from 'shared/styles/components/Table';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const ScheduleMaintenanceVehicleNameColumn = ({
  vehicle,
  selectableVehicles,
  selectedVehicles,
  setSelectedVehicles,
}) => {
  const vehicleName = vehicle.meta.label;

  return (
    <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + vehicleName}>
      {selectableVehicles.map(v => v.vehicle_id)?.includes(vehicle.vehicle_id) ? (
        <TableCellCheckboxWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={selectedVehicles?.map(sv => sv.vehicle_id)?.includes(vehicle.vehicle_id)}
              onClick={() => {
                selectedVehicles.map(sv => sv.vehicle_id)?.includes(vehicle.vehicle_id)
                  ? setSelectedVehicles({
                      selectedVehicles: [
                        ...selectedVehicles.filter(sv => sv.vehicle_id !== vehicle.vehicle_id),
                      ],
                    })
                  : setSelectedVehicles({ selectedVehicles: [...selectedVehicles, vehicle] });
              }}
            />
          </Checkbox>
        </TableCellCheckboxWrapper>
      ) : (
        <UnsupportedIconWrapper>
          <IconSvgComponent
            svgFileName="unsupported-checkbox"
            title="This vehicle does not support the selected interval type"
            alt="This vehicle does not support the selected interval type"
          />
        </UnsupportedIconWrapper>
      )}
      <PermissionProtectedElement
        requiredPermissions={[permissionData.editfleet]}
        alternativeComponent={<>{vehicleName}</>}
      >
        <Link
          to={{
            pathname: `/vehicle/${encodeURIComponent(vehicle.vehicle_id)}`,
          }}
          style={{ textDecoration: 'none' }}
        >
          {vehicleName}
        </Link>
      </PermissionProtectedElement>
    </span>
  );
};

export default ScheduleMaintenanceVehicleNameColumn;
