import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import ProgressBar from 'features/ota/shared/components/ProgressBar';
import { firmwareStatus } from 'features/ota/shared/constants/firmwareStatus';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { permissionData } from 'shared/constants/users';

const FirmwareUpdateTableByVehicle = ({
  deviceUpdates,
  handleUpdateFirmware,
  updatePostLoading,
  vehicle,
  handleUpdateListingCount,
  updatesInProgress,
}) => {
  deviceUpdates.forEach(du => {
    du.fwStatusDisplayName = firmwareStatus[du.firmware_status].displayName;
    du.fwStatusProgress = firmwareStatus[du.firmware_status].progress;
    du.fwStatusButtonText = firmwareStatus[du.firmware_status].buttonText
      ? firmwareStatus[du.firmware_status].buttonText
      : null;
  });

  return (
    <>
      <div>
        <SortablePaginatedTable
          className="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableData={deviceUpdates}
          rowKey={record => record.device_id}
          emptyText="No data to display."
          setListingCount={handleUpdateListingCount}
          columns={[
            {
              title: 'Device Type',
              dataIndex: 'product_name',
              key: 'a',
              searchable: true,
              render: (value, row) => {
                return (
                  <>
                    <> {value}</>
                  </>
                );
              },
            },
            {
              title: 'Device Id',
              dataIndex: 'device_id',
              key: 'b',
              searchable: true,
              render: deviceId => {
                return <>{deviceId}</>;
              },
            },
            {
              title: 'Initial Firmware Version',
              dataIndex: 'version_initial',
              key: 'c',
              searchable: true,
              render: value => {
                return <>{value}</>;
              },
            },

            {
              title: 'Target Firmware Version',
              dataIndex: 'version',
              key: 'd',
              searchable: true,
              render: value => {
                return <>{value}</>;
              },
            },
            {
              title: 'Firmware Status',
              dataIndex: 'fwStatusDisplayName',
              key: 'e',
              searchable: true,
              render: fwStatusDisplayName => {
                return <>{fwStatusDisplayName}</>;
              },
            },
            {
              title: 'Update Progress',
              dataIndex: 'fwStatusProgress',
              key: 'f',
              width: '15%',
              render: (fwStatusProgress, row) => {
                if (row.has_never_started_ota) {
                  return <></>;
                } else {
                  return <ProgressBar progress={fwStatusProgress} />;
                }
              },
            },
            {
              title: 'Update Action',
              dataIndex: 'fwStatusButtonText',
              className: 'action',
              key: 'g',
              render: (fwStatusButtonText, row) => {
                return fwStatusButtonText !== null ? (
                  <PermissionProtectedElement
                    requiredPermissions={[permissionData.editotafirmware]}
                  >
                    <ButtonWithLoader
                      disabled={
                        !vehicle?.meta.online ||
                        vehicle?.gps.fr_mode_enabled === 1 ||
                        updatesInProgress ||
                        updatePostLoading
                      }
                      loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                      notLoadingStyleProp={'otaUpdate'}
                      confirmText={fwStatusButtonText}
                      isLoading={row.device_id === updatePostLoading}
                      clickHandler={async () => {
                        await handleUpdateFirmware(row);
                      }}
                    />
                  </PermissionProtectedElement>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
export default FirmwareUpdateTableByVehicle;
