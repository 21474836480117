import mapboxgl from '!mapbox-gl';
import { useEffect, useRef, useState } from 'react';

import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import PlaybackVehicleDetailCard from 'app/features/playback/ui/components/VehiclePlaybackMapboxComponent/PlaybackVehicleDetailCard';
import {
  mapboxAccessToken,
  mapboxStyleUrl,
  toggleMapboxStyleUrl,
  whelenGpsCoordinates,
} from 'shared/constants/map';
import { vehicleOnlineStatusViewData, vehicleTypeViewData } from 'shared/constants/vehicle';
import {
  MapboxDiv,
  MapContentDiv,
  MapPerformanceModeDiv,
} from 'shared/styles/components/SharedMapStyledComponents';
import { VehicleDetailCardContainer } from 'shared/styles/components/VehicleDetailCard';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { getHeatmapLocationInLocal, getLastMapLocationInLocal } from 'shared/utilities/localStore';
import { animatePlaybackLocation } from 'shared/utilities/map/MapAnimationUtils';
import { MapboxCustomControls } from 'shared/utilities/map/MapboxCustomControls';
import MapControls from 'shared/widgets/MapControls';
import { groupPoints } from 'app/features/playback/services/Utilities/getGroupedPoints';
import { directionalPoints } from 'app/features/playback/services/Utilities/getDirectionalPoints';

const statusIconMap = {
  active: 'notRespondingDirectionArrow',
  responding: 'respondingDirectionArrow',
};

const mapboxDataSources = {
  FULL_PLAYBACK_DATA: 'FULL_PLAYBACK_DATA',
  FULL_PLAYBACK_LINES_DATA: 'FULL_PLAYBACK_LINES_DATA',
  FULL_PLAYBACK_DIRECTIONAL_DATA: 'FULL_PLAYBACK_DIRECTIONAL_DATA',
  RESPONDING_PLAYBACK_DATA: 'RESPONDING_PLAYBACK_DATA',
  CURRENT_PLAYBACK_LOCATION_DATA: 'CURRENT_PLAYBACK_LOCATION_DATA',
  SELECTED_PLAYBACK_LOCATION_DATA: 'SELECTED_PLAYBACK_LOCATION_DATA',
};

const VehiclePlaybackMapboxComponent = () => {
  const {
    actions: { setPlaybackAnimationControlsAction },
    state: {
      playbackAnimationControls: {
        currentPlaybackDataIndex,
        selectedPlaybackDataIndex,
        playbackSpeed,
      },
      playbackData: { selectedVehicle, locationData },
    },
  } = useVehiclePlaybackProviderContext();

  /** REFS */
  const mapbox = useRef(null);
  const mapContainer = useRef(null);
  const mapControls = useRef(null);

  /** STATE **/
  const [mapLoaded, setMapLoaded] = useState(false);
  const [fullPlaybackDataFeatures, setFullPlaybackDataFeatures] = useState(null); // all playback dots
  const [fullPlaybackDataLinesFeatures, setFullPlaybackDataLinesFeatures] = useState(null); // all playback lines
  const [fullPlaybackDataDirectionalFeatures, setFullPlaybackDataDirectionalFeatures] = useState(
    null,
  ); // all directional symbols
  const [respondingPlaybackDataFeatures, setRespondingPlaybackDataFeatures] = useState(null); // responding dots
  const [currentPlaybackLocationFeatures, setCurrentPlaybackLocationFeatures] = useState(null); // vehicle marker
  const [selectedPlaybackLocationFeatures, setSelectedPlaybackLocationFeatures] = useState(null); // selected dot
  const [playbackPerformanceMode, setPlaybackPerformanceMode] = useState(false);
  const [mapStyle, setMapStyle] = useState(mapboxStyleUrl);

  /** HELPERS **/

  // initialize mapbox
  const initializeMap = ({ mapContainer }) => {
    mapboxgl.accessToken = mapboxAccessToken;

    let sessionBounds = getHeatmapLocationInLocal();

    let map = new mapboxgl.Map({
      container: mapContainer.current,
      style: mapStyle,
      dragRotate: false,
      maxZoom: 19,
      bounds: sessionBounds ? sessionBounds : whelenGpsCoordinates,
      fitBoundsOptions: { padding: 50, maxZoom: 10 },
    });

    const mapLoadedEvent = new Event('mapLoaded', { bubbles: true });
    window.document.dispatchEvent(mapLoadedEvent);

    map.on('load', function() {
      map.addSource(mapboxDataSources.FULL_PLAYBACK_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: fullPlaybackDataFeatures || [],
        },
      });

      map.addSource(mapboxDataSources.FULL_PLAYBACK_DIRECTIONAL_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: fullPlaybackDataDirectionalFeatures || [],
        },
      });

      map.addSource(mapboxDataSources.FULL_PLAYBACK_LINES_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: fullPlaybackDataLinesFeatures || [],
        },
      });

      map.addSource(mapboxDataSources.RESPONDING_PLAYBACK_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: respondingPlaybackDataFeatures || [],
        },
      });

      map.addSource(mapboxDataSources.SELECTED_PLAYBACK_LOCATION_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: currentPlaybackLocationFeatures || [],
        },
      });

      map.addSource(mapboxDataSources.CURRENT_PLAYBACK_LOCATION_DATA, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: selectedPlaybackLocationFeatures || [],
        },
      });

      map.addLayer({
        id: 'background-layer',
        type: 'background',
        paint: {
          'background-color': 'rgba(0, 0, 0, 0.4)',
        },
      });

      map.addLayer({
        id: 'vehicle-playback-directional-layer',
        type: 'symbol',
        source: mapboxDataSources.FULL_PLAYBACK_DIRECTIONAL_DATA,
        layout: {
          'icon-image': ['get', 'icon_image'],
          'icon-size': ['interpolate', ['linear'], ['zoom'], 2, 0.4, 19, 0.7],
          'icon-rotate': ['get', 'icon_rotate'], // Rotate the icon based on the heading field
          'icon-allow-overlap': true,
        },
      });

      map.addLayer({
        id: 'vehicle-playback-line-layer',
        type: 'line',
        source: mapboxDataSources.FULL_PLAYBACK_LINES_DATA,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': [
            'match',
            ['get', 'status'],
            'active',
            '#ffffff',
            'responding',
            '#bb0208',
            '#ffffff',
          ],
          'line-width': 5,
        },
      });

      map.addLayer({
        id: 'vehicle-playback-layer',
        type: 'circle',
        source: mapboxDataSources.FULL_PLAYBACK_DATA,
        paint: {
          'circle-color': [
            'match',
            ['get', 'status'],
            'active',
            '#ffffff',
            'responding',
            '#bb0208',
            '#ffffff',
          ],
        },
      });

      map.addLayer({
        id: 'vehicle-playback-layer-responding-only',
        type: 'circle',
        source: mapboxDataSources.RESPONDING_PLAYBACK_DATA,
        paint: {
          'circle-color': '#bb0208',
        },
      });

      map.addLayer({
        id: 'selected-vehicle-playback-location',
        type: 'circle',
        source: mapboxDataSources.SELECTED_PLAYBACK_LOCATION_DATA,
        paint: {
          'circle-color': '#3571b8',
          'circle-stroke-width': 1,
          'circle-stroke-color': 'white',
        },
      });

      map.addLayer({
        id: 'current-vehicle-playback-location',
        source: mapboxDataSources.CURRENT_PLAYBACK_LOCATION_DATA,
        type: 'symbol',
        layout: {
          'icon-image': {
            property: 'icon_type',
            type: 'identity',
          },
          'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 19, 0.9],

          'icon-rotate': {
            property: 'icon_rotate',
            type: 'identity',
          },
          'icon-ignore-placement': true,
          'icon-allow-overlap': true,
        },
      });

      setMapLoaded(true);
    });

    map.on('click', 'vehicle-playback-layer', e => {
      setPlaybackAnimationControlsAction({
        selectedPlaybackDataIndex: e.features[0].properties.dataIndex,
      });
    });

    map.on('mouseenter', 'vehicle-playback-layer', () => {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'vehicle-playback-layer', () => {
      map.getCanvas().style.cursor = '';
    });

    map.addControl(
      new MapboxCustomControls(
        (
          <MapPerformanceModeDiv>
            <div
              onClick={() => {
                setPlaybackPerformanceMode(current => !current);
              }}
            >
              {playbackPerformanceMode ? (
                <IconSvgComponent
                  svgFileName={'map-performance-mode-enabled'}
                  alt="Toggle Performance Mode Off "
                  title="Toggle Performance Mode Off "
                />
              ) : (
                <IconSvgComponent
                  svgFileName={'map-performance-mode'}
                  alt="Toggle Performance Mode On for older hardware"
                  title="Toggle Performance Mode On for older hardware"
                />
              )}
            </div>
          </MapPerformanceModeDiv>
        ),
      ),
      'top-right',
    );
    // set ref to mapbox object
    mapbox.current = map;
  };

  // render map data
  const renderFeatureCollection = ({ collectionName, featureData }) => {
    if (mapbox.current) {
      const source = mapbox.current.getSource(collectionName);
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: featureData || [],
        });
      }
    }
  };

  // map location data to GeoJSON
  const mapPlaybackDataToFeature = ({ status, long, lat }, dataIndex) => {
    return {
      type: 'Feature',
      properties: {
        status: status,
        dataIndex,
      },
      geometry: {
        type: 'Point',
        coordinates: [long, lat],
      },
    };
  };

  const mapPlaybackDirectionalDataToFeature = ({ status, midwayPoints }) => {
    return midwayPoints.map(point => ({
      type: 'Feature',
      properties: {
        status: status,
        icon_rotate: point.heading,
        icon_image: statusIconMap[status],
      },
      geometry: {
        type: 'Point',
        coordinates: [point.long, point.lat],
      },
    }));
  };

  const mapPlaybackLineDataToFeature = ({ status, points, id }, dataIndex) => {
    return {
      type: 'Feature',
      properties: {
        status: status,
        dataIndex,
      },
      geometry: {
        type: 'LineString',
        coordinates: points,
      },
    };
  };

  // update map state helpers
  const updateFullPlaybackMapState = () => {
    setFullPlaybackDataFeatures(locationData.map(mapPlaybackDataToFeature));
  };

  const updateFullPlaybackLinesMapState = () => {
    let lines = groupPoints(locationData);
    let featureData = lines.map(mapPlaybackLineDataToFeature);
    renderFeatureCollection({
      collectionName: mapboxDataSources.FULL_PLAYBACK_LINES_DATA,
      featureData: featureData,
    });
    setFullPlaybackDataLinesFeatures(featureData);
  };

  const updateFullPlaybackDirectionalMapState = () => {
    let result = directionalPoints(locationData);
    let featureData = result.flatMap(mapPlaybackDirectionalDataToFeature);
    renderFeatureCollection({
      collectionName: mapboxDataSources.FULL_PLAYBACK_DIRECTIONAL_DATA,
      featureData: featureData,
    });
    setFullPlaybackDataDirectionalFeatures(featureData);
  };

  const updateRespondingPlaybackMapState = () => {
    setRespondingPlaybackDataFeatures(
      locationData.filter(d => d.status === 'responding').map(mapPlaybackDataToFeature),
    );
  };

  const updateSelectedLocationMapState = () => {
    if (selectedPlaybackDataIndex === null) {
      setSelectedPlaybackLocationFeatures(null);
    } else if (locationData.length > 0) {
      let { status, long, lat } = locationData[selectedPlaybackDataIndex];
      let features = {
        type: 'Feature',
        properties: {
          status,
          dataIndex: selectedPlaybackDataIndex,
        },
        geometry: {
          type: 'Point',
          coordinates: [long, lat],
        },
      };
      setSelectedPlaybackLocationFeatures([features]);
    }
  };

  const updateCurrentLocationMapState = (
    coordinates,
    heading,
    status,
    currentPlaybackDataIndex,
  ) => {
    if (selectedVehicle) {
      // map to feature
      let features = {
        type: 'Feature',
        properties: {
          status: status,
          dataIndex: currentPlaybackDataIndex,
          type: selectedVehicle.meta.vehicle_type,
          icon_type: `${vehicleTypeViewData[selectedVehicle.meta.vehicle_type].icon}${
            selectedVehicle.onlineStatus === vehicleOnlineStatusViewData.INACTIVE.id
              ? '_inactive'
              : ''
          }`,
          icon_rotate: heading,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      };
      setCurrentPlaybackLocationFeatures([features]);
    }
  };

  // ANIMATION
  let startTime = useRef(null);
  let frameHandle = useRef(null);

  const cancelAnimation = frameHandle => {
    cancelAnimationFrame(frameHandle);
  };

  const triggerAnimation = startingCoordinates => {
    if (locationData) {
      let currentLocation = locationData[currentPlaybackDataIndex];
      let previousLocation = locationData[currentPlaybackDataIndex - 1];

      // reset animation start time
      startTime.current = null;
      if (selectedVehicle && locationData && currentLocation) {
        frameHandle.current = requestAnimationFrame(
          animatePlaybackLocation({
            renderMapData: (coordinates, heading, status) =>
              updateCurrentLocationMapState(coordinates, heading, status, currentPlaybackDataIndex),
            startTime,
            frameHandle,
            duration: playbackSpeed,
            startLocation: currentPlaybackDataIndex === 0 ? currentLocation : previousLocation,
            endLocation: currentLocation,
            playbackPerformanceMode,
          }),
        );
      }
    }
  };

  /** EFFECTS **/

  // init map
  useEffect(() => {
    //the next line makes sure the vehicle is visible after a toggle of playbackPerformanceMode
    setMapLoaded(false);
    if (!mapbox.current) initializeMap({ mapContainer });
    mapbox.current.setStyle(mapStyle);
    if (mapbox.current.hasControl(mapControls.current)) {
      mapbox.current.removeControl(mapControls.current);
    }
    mapControls.current = new MapboxCustomControls(
      (
        <MapControls
          map={mapbox.current}
          showSatelliteLayerToggle={true}
          showFocusToggle={true}
          toggleSatelliteView={() => {
            setMapStyle(toggleMapboxStyleUrl(mapStyle));
          }}
          focusBounds={() => {
            checkSessionBounds();
          }}
        />
      ),
    );
    mapbox.current.addControl(mapControls.current, 'bottom-right');
    //re-focus after reloading map
    checkSessionBounds();

    return () => {
      mapbox.current.remove();
      mapbox.current = null;
    };
  }, [playbackPerformanceMode, mapStyle]);

  useEffect(() => {
    if (locationData) {
      let currentLocation = locationData[currentPlaybackDataIndex];

      if (currentLocation) {
        updateCurrentLocationMapState(
          [currentLocation.long, currentLocation.lat],
          currentLocation.heading,
          currentLocation.status,
          currentPlaybackDataIndex,
        );
      }
    }
  }, [locationData, selectedVehicle]);

  useEffect(() => {
    if (locationData) {
      if (playbackSpeed) {
        triggerAnimation();
      } else {
        cancelAnimation(frameHandle.current);
      }
    }
    return () => {
      cancelAnimation(frameHandle.current);
    };
  }, [locationData, currentPlaybackDataIndex, playbackSpeed]);

  useEffect(() => {
    cancelAnimation(frameHandle.current);
  }, [playbackSpeed]);

  // update map data
  useEffect(() => {
    if (locationData) {
      updateFullPlaybackMapState();
      updateFullPlaybackLinesMapState();
      updateFullPlaybackDirectionalMapState();
      updateRespondingPlaybackMapState();
      updateSelectedLocationMapState();
    }
  }, [locationData, selectedPlaybackDataIndex, mapLoaded]);

  // render

  useEffect(() => {
    if (mapLoaded) {
      renderFeatureCollection({
        collectionName: mapboxDataSources.CURRENT_PLAYBACK_LOCATION_DATA,
        featureData: currentPlaybackLocationFeatures,
      });
    }
  }, [currentPlaybackLocationFeatures, mapLoaded]);

  useEffect(() => {
    if (mapLoaded) {
      renderFeatureCollection({
        collectionName: mapboxDataSources.FULL_PLAYBACK_DATA,
        featureData: fullPlaybackDataFeatures,
      });
    }
  }, [fullPlaybackDataFeatures, mapLoaded]);

  useEffect(() => {
    if (mapLoaded) {
      renderFeatureCollection({
        collectionName: mapboxDataSources.RESPONDING_PLAYBACK_DATA,
        featureData: respondingPlaybackDataFeatures,
      });
    }
  }, [respondingPlaybackDataFeatures, mapLoaded]);

  useEffect(() => {
    if (mapLoaded) {
      renderFeatureCollection({
        collectionName: mapboxDataSources.SELECTED_PLAYBACK_LOCATION_DATA,
        featureData: selectedPlaybackLocationFeatures,
      });
    }
  }, [selectedPlaybackLocationFeatures, mapLoaded]);

  const checkSessionBounds = () => {
    if (locationData?.length > 0) {
      determinePlaybackDataCoordinates();
    } else {
      let bounds = mapbox.current.sessionBounds
        ? mapbox.current.sessionBounds
        : getLastMapLocationInLocal();
      mapbox.current.cameraForBounds(bounds ? bounds : whelenGpsCoordinates, {
        padding: 50,
      });
      mapbox.current.fitBounds(bounds ? bounds : whelenGpsCoordinates, {
        padding: { top: 150, bottom: 150, left: 300, right: 50 },
        maxZoom: 15,
      });
    }
  };
  const determinePlaybackDataCoordinates = () => {
    if (selectedVehicle && locationData && locationData.length > 0) {
      const lats = Object.values(locationData).map(v => v.lat);
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const lngs = Object.values(locationData).map(v => v.long);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);
      mapbox.current.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: { top: 150, bottom: 150, left: 300, right: 50 },
          maxZoom: 15,
        },
      );
      mapbox.current.sessionBounds = [
        [minLng, minLat],
        [maxLng, maxLat],
      ];
    }
  };
  useEffect(() => {
    determinePlaybackDataCoordinates();
  }, [selectedVehicle, locationData, playbackPerformanceMode]);

  return (
    <MapContentDiv>
      {selectedPlaybackDataIndex && (
        <VehicleDetailCardContainer playbackCard>
          <PlaybackVehicleDetailCard
            selectedVehicle={selectedVehicle}
            locationData={locationData}
            selectedPlaybackDataIndex={selectedPlaybackDataIndex}
            handleRequestClose={() => {
              setPlaybackAnimationControlsAction({
                selectedPlaybackDataIndex: null,
              });
            }}
          />
        </VehicleDetailCardContainer>
      )}
      <MapboxDiv ref={mapContainer} />
    </MapContentDiv>
  );
};

export default VehiclePlaybackMapboxComponent;
