import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getVehicles } from 'core/api/vehicles';
import { checkLogin } from 'shared/utilities/user';
import { decorateVehicle, sortAscendingAlphaLabel } from 'shared/utilities/vehicle';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import { vehicleFetchError } from 'features/fleet/vehicles/helpers/notifications';

export const useFetchVehiclesQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  cachingEnabled = true,
  apiFlags = null,
} = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();
  const [vehicles, setVehicles] = useState(null);

  const options = {
    enabled: autoRun && checkLogin(),
    onSuccess: ({ response: { message: vehicles } }) => {
      vehicles = vehicles.sort(sortAscendingAlphaLabel).map(v => decorateVehicle(v));
      setVehicles(vehicles);
    },
    onError: err => dispatch(vehicleFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    cacheTime: cachingEnabled ? 5 * 60 * 1000 : 0,
  };

  const vehicleQuery = useQuery(
    ['fetchVehiclesQuery' + key, filteredOrganizationId],
    () => {
      return getVehicles({ organizationId: filteredOrganizationId, apiFlags });
    },
    options,
  );

  return [vehicleQuery, vehicles];
};
