import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getFirmwares } from 'core/api/firmware';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { updateFetchError } from 'features/ota/firmware/helpers/notifications';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import {
  FIELD_SOLUTION_ENGINEER,
  ORGANIZATION_ADMIN,
  FLEET_ANALYST,
  permissionData,
  userRoleOptions,
} from 'shared/constants/users';
import { checkLogin } from 'shared/utilities/user';

const useFetchFirmwareQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  cachingEnabled = true,
} = {}) => {
  const dispatch = useDispatch();

  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const user = useSelector(state => state.user);
  const userRole = useSelector(state => state.user.user.userRole);

  const [updates, setFirmwareUpdateData] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    let hasPermissions = checkForUserPermission(user, permissionData.viewotafirmware);
    setHasPermissions(hasPermissions);
  });

  useEffect(() => {
    if (!hasPermissions) setFirmwareUpdateData([]);
  }, [hasPermissions]);

  const options = {
    enabled: autoRun && checkLogin() && !!hasPermissions,
    onSuccess: ({
      data: {
        message: { updates },
      },
    }) => {
      setFirmwareUpdateData(updates);
    },
    onError: err => dispatch(updateFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    cacheTime: cachingEnabled ? 5 * 60 * 1000 : 0,
  };
  // gets firmware updates
  const fetchUpdatesQuery = useQuery(
    ['fetchFirmwareUpdatesQuery' + key],
    () => {
      return getFirmwares({ organizationId: filteredOrganizationId });
    },
    options,
  );
  return [fetchUpdatesQuery, updates];
};
export default useFetchFirmwareQuery;
