import { RedText } from 'shared/styles/components/ManageAlerts';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { SORT_ORDER } from 'shared/constants/table';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import ManageAlertsLogsActionMenu from 'app/features/alerts/ui/components/AlertLogsSummary/menus/ManageAlertsLogsActionMenu';
import { addDisplayCommas } from 'app/features/maintenance/utilities/formatting';

const ManageAlertsLogsTable = ({ alertLogs, handleUpdateListingCount }) => {
  const getRecipientTooltip = alertLog => {
    return `Recipients: ${alertLog.testModeEnabled ? 'TEST MODE - ' : ''}${
      alertLog.formattedUserDisplayNames
    }
    `;
  };
  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      searchIndex: 'formattedTime',
      key: 'timestamp',
      searchable: true,
      width: '25%',
      render(value, row) {
        return <span title={'Timestamp: ' + row.formattedTime}>{row.formattedTime}</span>;
      },
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicleName',
      key: 'vehicleName',
      className: 'generic',
      searchable: true,
      width: '10%',
      render(value) {
        return (
          <span title={`Vehicle: ${value} `}>
            <TruncatedTextWithWrap>{value}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Condition',
      dataIndex: 'alertTypeDisplay',
      key: 'alertTypeDisplay',
      className: 'generic',
      width: '15%',
      searchable: true,
      render(value) {
        return <span title={'Condition: ' + value}>{value}</span>;
      },
    },

    {
      title: 'Alert Value',
      dataIndex: 'triggerValueDisplay',
      key: 'triggerValueDisplay',
      className: 'generic',
      searchable: true,
      width: '10%',
      render(value) {
        return <span title={`Value: ${value} `}>{value}</span>;
      },
    },
    {
      title: 'Measured Value',
      dataIndex: 'measuredValueDisplay',
      key: 'measuredValueDisplay',
      className: 'generic',
      searchable: true,
      width: '10%',
      render(value) {
        const measuredValue = addDisplayCommas(value);
        return <RedText title={`Value: ${measuredValue}`}>{measuredValue}</RedText>;
      },
    },

    {
      title: 'Recipients',
      dataIndex: 'alertRecipients',
      className: 'generic',
      searchIndex: 'formattedUserDisplayNames',
      key: 'alertRecipients',
      searchable: true,
      render(value, alertLog) {
        return (
          <span title={getRecipientTooltip(alertLog)}>
            <TruncatedTextWithWrap>
              {alertLog.testModeEnabled ? 'TEST MODE - ' : ''}
              {alertLog.formattedUserDisplayNames}
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Method',
      dataIndex: 'aggregateAlertMethod',
      className: 'generic',
      searchIndex: 'aggregateAlertMethod',
      key: 'aggregateAlertMethod',
      searchable: true,
      render(value, row) {
        return <span title={`Method: ${value} `}>{value}</span>;
      },
    },
    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'a',
      render(log) {
        return <ManageAlertsLogsActionMenu log={log} />;
      },
    },
  ];

  return (
    <SortablePaginatedTable
      className="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableData={alertLogs ? alertLogs : []}
      //need to change the key here, probbably a multivariable key as well to guarentee uniqueness
      rowKey={record =>
        record.alertId + record.timestamp + record.measuredValueDisplay + record.vehicleName
      }
      emptyText="No alert logs are currently associated with this alert"
      setListingCount={handleUpdateListingCount}
      defaultSortOrder={SORT_ORDER.DESC}
    />
  );
};

export default ManageAlertsLogsTable;
