//must be first import
import store from 'core/redux/store';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'app/App';

import { clientSideID } from './tools/launchdarkly/ld-constants';
import { unregister } from './registerServiceWorker';
import { GlobalBaseStyles } from './shared/styles/global/base-styles';
import { GlobalModalStyles } from './shared/styles/global/modal-styles';
import { GlobalResetStyles } from './shared/styles/global/reset-styles';
import { GlobalTableStyles } from './shared/styles/global/table-styles';

import './index.css';
import './index.css';
import './shared/styles/global/base-styles';
import './shared/styles/global/modal-styles';
import './shared/styles/global/reset-styles';
import './shared/styles/global/table-styles';
import 'rc-table/assets/index.css';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID,
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <ReduxProvider store={store}>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalResetStyles />
          <GlobalBaseStyles />
          <GlobalModalStyles />
          <GlobalTableStyles />
          <BrowserRouter>
            <App />
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </LDProvider>
    </ReduxProvider>,
  );
})();

unregister();
