import { assignConfirgurationToGroupAPI } from 'core/api/groups';
import GroupNameField from 'features/fleet/groups/components/form/fields/text/GroupNameField';
import { useCreateGroupMutation } from 'features/fleet/groups/hooks/useCreateGroupMutation';
import { useFetchGroupByIdQuery } from 'features/fleet/groups/hooks/useFetchGroupByIdQuery';
import { useUpdateGroupMutation } from 'features/fleet/groups/hooks/useUpdateGroupMutation';
import { useFetchVehiclesQuery } from 'features/fleet/vehicles/hooks/useFetchVehiclesQuery';
import React from 'react';
import { useState } from 'react';
import AssignConfigurationToGroupContent from 'shared/modals/AssignConfigurationToGroupContent';

import ModalPortal from 'shared/modals/ModalPortal';
import { FormRow } from 'shared/styles/components/Form';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import GenericFormModal from 'shared/ui/modals/GenericFormModal';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { validateGroup, validateSelectedConfiguration } from 'shared/utilities/validators';

export const AssignConfigurationsModal = ({ modalData: { show, groupId }, handleCloseModal }) => {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [fetchGroupByIdQuery, group] = useFetchGroupByIdQuery({ groupId, enabled: show });
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    autoRun: show,
    apiFlags: {
      addons: false,
      cell: false,
      device: true,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  return (
    show &&
    (fetchGroupByIdQuery.isFetching || fetchVehiclesQuery.isFetching ? (
      <LoadingOverlay />
    ) : (
      <GenericFormModal
        customWidth={550}
        icon={<IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />}
        title={'Assign Configuration'}
        initialValues={{
          groupName: group && group.group_name,
          selectedConfiguration: '',
        }}
        submitHandler={async ({ selectedConfiguration }, formikActions) => {
          // TODO convert to hook
          const { response } = await assignConfirgurationToGroupAPI({
            group,
            selectedConfiguration,
          });
          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validate={values => {
          let validation = validateSelectedConfiguration(values);
          return validation;
        }}
        handleCloseModal={handleCloseModal}
        // errorMessage={createGroupMutation.isError ? createGroupMutation.error : null}
        errorMessage={null}
        submitButtonText={'Assign'}
        submitButtonDisabled={disableSubmit}
      >
        {formikProps => (
          <AssignConfigurationToGroupContent
            vehicles={vehicles}
            group={group}
            formikProps={formikProps}
            setDisabled={setDisableSubmit}
          />
        )}
      </GenericFormModal>
    ))
  );
};

export default AssignConfigurationsModal;
