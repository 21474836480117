import { apiCall } from 'core/api/API';
import {
  mapMaintenanceDataToFrontend,
  mapMaintenanceTotalMilageData,
} from 'app/features/maintenance/utilities/mapping';

export const getMaintenanceApi = async ({ organizationId }) => {
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  const { response } = await apiCall({
    path: `/maintenance`,
    method: 'get',
    queryStringParameters,
  });
  const events = response.message.events;
  let mappedEvents = events ? events.map(item => mapMaintenanceDataToFrontend(item)) : [];

  mappedEvents = mappedEvents.map(e => mapMaintenanceTotalMilageData({ maintenanceEvent: e }));

  return { events: mappedEvents };
};
