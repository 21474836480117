import ScheduleMaintenanceVehicleNameColumn from 'app/features/maintenance/components/MaintenanceScheduler/table/ScheduleMaintenanceVehicleNameColumn';
import { getLastIn, getObdDisplayValue } from 'app/features/maintenance/utilities/formatting';
import { SmallTableWrapper } from 'shared/styles/components/Table';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';

const ScheduleMaintenanceTable = ({
  vehicleRecords,
  selectableVehicles,
  selectedVehicles,
  setSelectedVehicles,
  handleUpdateListingCount,
}) => {
  const columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'meta.label',
      key: 'name',
      className: 'name',
      searchable: true,
      render(_, vehicle) {
        return (
          <ScheduleMaintenanceVehicleNameColumn
            vehicle={vehicle}
            selectableVehicles={selectableVehicles}
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
          />
        );
      },
    },
    {
      title: 'Make',
      dataIndex: 'meta.make',
      key: 'make',
      className: 'make',
      searchable: true,
      width: '25%',
      render(value) {
        return <span title={'Make: ' + value}>{value}</span>;
      },
    },

    {
      title: 'Odometer',
      dataIndex: 'obd.odometer',
      key: 'odometer',
      searchable: true,
      render(value) {
        return (
          <span title={'Odometer: ' + getObdDisplayValue(value)}>{getObdDisplayValue(value)}</span>
        );
      },
    },
    {
      title: 'Engine Hours',
      dataIndex: 'obd.engine_hours',
      key: 'engine_hours',
      searchable: true,
      render(value) {
        return (
          <span title={'Engine Hours: ' + getObdDisplayValue(value)}>
            {getObdDisplayValue(value)}
          </span>
        );
      },
    },
    {
      title: 'Last In',
      dataIndex: 'obd.last_in',
      key: 'last_in',
      width: '20%',
      searchable: true,
      render(value) {
        return <span title={'Last In: ' + getLastIn(value)}>{getLastIn(value)}</span>;
      },
    },
  ];

  return (
    <SmallTableWrapper>
      <SortablePaginatedTable
        smallTable={true}
        className="table-simple"
        rowClassName={(record, i) => `row-${i}`}
        columns={columns}
        tableData={vehicleRecords || []}
        rowKey={record => record.vehicle_id}
        emptyText="No Vehicles are present in this organization"
        setListingCount={handleUpdateListingCount}
      />
    </SmallTableWrapper>
  );
};

export default ScheduleMaintenanceTable;
