import { useSuppressMaintenanceAlertsMutation } from 'app/features/maintenance/hooks/mutations/useSuppressAlertsMutation';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import SimpleConfirmationModal from 'shared/ui/modals/SimpleConfirmationModal';

const SuppressAlertsModal = ({ modalData: { show, event }, handleCloseModal }) => {
  const suppressAlertsMutation = useSuppressMaintenanceAlertsMutation();
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={suppressAlertsMutation.isLoading}
        submitHandler={async () => {
          try {
            let result = await suppressAlertsMutation.mutateAsync({
              maintenanceEvent: event,
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            handleCloseModal();
          }
        }}
        bodyText={`Suppressing alerts for this check engine event will hide the check engine alert from the vehicle details on the live map,
        as well as the alert sidebar. This maintenance event will remain in the listing here so that it can be addressed at a later time.`}
        title={`Suppress Alert Messages`}
        confirmText="Suppress"
        cancelText="Cancel"
      />
    )
  );
};
export default SuppressAlertsModal;
