import ProtectedElement from 'core/components/authorization/ProtectedElement';
import { checkForUserPermission } from 'core/redux/user/selectors';
import AssignAddonsMenuItem from 'features/fleet/groups/components/menus/menuItems/AssignAddonsMenuItem';
import AssignConfigurationMenuItem from 'features/fleet/groups/components/menus/menuItems/AssignConfigurationMenuItem';
import DeleteGroupMenuItem from 'features/fleet/groups/components/menus/menuItems/DeleteGroupMenuItem';
import DuplicateGroupMenuItem from 'features/fleet/groups/components/menus/menuItems/DuplicateGroupMenuItem';
import EditGroupMenuItem from 'features/fleet/groups/components/menus/menuItems/EditGroupMenuItem';
import ManageHaasIntegrationMenuItem from 'features/fleet/groups/components/menus/menuItems/ManageHaasIntegrationMenuItem';
import RenameGroupMenuItem from 'features/fleet/groups/components/menus/menuItems/RenameGroupMenuItem';
import ResetAllGroupVehiclesMenuItem from 'features/fleet/groups/components/menus/menuItems/ResetAllGroupVehiclesMenuItem';
import AssignConfigurationsModal from 'features/fleet/groups/components/modals/AssignConfigurationsModal';
import DeleteGroupModal from 'features/fleet/groups/components/modals/DeleteGroupModal';
import DuplicateGroupModal from 'features/fleet/groups/components/modals/DuplicateGroupModal';
import ManageHaasIntegrationModal from 'features/fleet/groups/components/modals/ManageHaasIntegrationModal';
import RenameGroupModal from 'features/fleet/groups/components/modals/RenameGroupModal';
import ResetGroupVehiclesModal from 'features/fleet/groups/components/modals/ResetGroupVehiclesModal';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  allAdminUserRoles,
  FIELD_SOLUTION_ENGINEER,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  permissionData,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import ActionMenu from 'shared/ui/table/ActionMenu';

const GroupListActionMenu = ({ group, permissions }) => {
  const [duplicateGroupModalData, setDuplicateModalData] = useState({ show: false });
  const [assignConfigurationsModalData, setAssignConfigurationsModalData] = useState({
    show: false,
  });
  const [manageHaasConfigurationsModalData, setManageHaasConfigurationsModalData] = useState({
    show: false,
  });
  const [renameGroupModalData, setRenameGroupModalData] = useState({ show: false });
  const [deleteGroupModalData, setDeleteGroupModalData] = useState({ show: false });
  const [resetGroupVehiclesModalData, setResetGroupVehiclesModalData] = useState({ show: false });

  useEffect(() => {
    setDuplicateModalData({
      show: false,
      groupName: group.group_name,
      vehicleIds: group.vehicle_id,
    });

    setAssignConfigurationsModalData({
      show: false,
      groupId: group.group_id,
      vehicleIds: group.vehicle_id,
    });

    setManageHaasConfigurationsModalData({
      show: false,
      groupId: group.group_id,
      vehicleIds: group.vehicle_id,
    });

    setRenameGroupModalData({
      show: false,
      groupId: group.group_id,
      groupName: group.group_name,
    });

    setDeleteGroupModalData({
      show: false,
      groupId: group.group_id,
      groupName: group.group_name,
    });

    setResetGroupVehiclesModalData({
      show: false,
      vehicleIds: group.vehicle_id,
    });
  }, [group]);

  const organization = useSelector(state =>
    state.organizations.organizations.find(
      o => o.organizationId === state.user.filteredOrganizationId,
    ),
  );

  return (
    permissions.editAccess && (
      <>
        <ActionMenu>
          <EditGroupMenuItem groupName={group.group_name} />
          <RenameGroupMenuItem
            onMenuItemClick={() =>
              setRenameGroupModalData(prevState => ({ ...prevState, show: true }))
            }
          />
          {permissions.viewConfigAccess && group.vehicle_count > 0 && (
            <AssignConfigurationMenuItem
              onMenuItemClick={() =>
                setAssignConfigurationsModalData(prevState => ({ ...prevState, show: true }))
              }
            />
          )}
          {organization.haasEnabled && organization.haasSetup && (
            <ManageHaasIntegrationMenuItem
              onMenuItemClick={() =>
                setManageHaasConfigurationsModalData(prevState => ({ ...prevState, show: true }))
              }
            />
          )}

          {group.vehicle_count > 0 && (
            <AssignAddonsMenuItem
              groupId={group?.orgdata?.group_id ? group.orgdata.group_id : group.group_id}
            />
          )}

          <DuplicateGroupMenuItem
            onMenuItemClick={() =>
              setDuplicateModalData(prevState => ({ ...prevState, show: true }))
            }
          />

          {group.vehicle_count > 0 && (
            <ProtectedElement
              allowedRoles={[
                userRoles[FIELD_SOLUTION_ENGINEER].value,
                userRoles[SYSTEM_ADMIN].value,
                userRoles[FLEET_ANALYST].value,
                userRoles[ORGANIZATION_ADMIN].value,
              ]}
            >
              <ResetAllGroupVehiclesMenuItem
                onMenuItemClick={() =>
                  setResetGroupVehiclesModalData(prevState => ({ ...prevState, show: true }))
                }
              />
            </ProtectedElement>
          )}
          <ProtectedElement allowedRoles={allAdminUserRoles}>
            <DeleteGroupMenuItem
              onMenuItemClick={() =>
                setDeleteGroupModalData(prevState => ({ ...prevState, show: true }))
              }
            />
          </ProtectedElement>
        </ActionMenu>
        {/* Modals */}
        <>
          <RenameGroupModal
            modalData={renameGroupModalData}
            handleCloseModal={() =>
              setRenameGroupModalData(prevState => ({ ...prevState, show: false }))
            }
          />
          <DuplicateGroupModal
            modalData={duplicateGroupModalData}
            handleCloseModal={() =>
              setDuplicateModalData(prevState => ({ ...prevState, show: false }))
            }
          />
          <DeleteGroupModal
            modalData={deleteGroupModalData}
            handleCloseModal={() =>
              setDeleteGroupModalData(prevState => ({ ...prevState, show: false }))
            }
          />
          <ResetGroupVehiclesModal
            modalData={resetGroupVehiclesModalData}
            handleCloseModal={() =>
              setResetGroupVehiclesModalData(prevState => ({ ...prevState, show: false }))
            }
          />
          <AssignConfigurationsModal
            modalData={assignConfigurationsModalData}
            handleCloseModal={() =>
              setAssignConfigurationsModalData(prevState => ({ ...prevState, show: false }))
            }
          />
          <ManageHaasIntegrationModal
            modalData={manageHaasConfigurationsModalData}
            handleCloseModal={() =>
              setManageHaasConfigurationsModalData(prevState => ({ ...prevState, show: false }))
            }
          />
        </>
      </>
    )
  );
};

export default React.memo(
  connect(state => ({
    permissions: {
      editAccess: checkForUserPermission(state.user, permissionData.editfleet),
      viewConfigAccess: checkForUserPermission(state.user, permissionData.viewotaconfig),
    },
  }))(GroupListActionMenu),
);
