/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependencies
 */
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import theme from 'shared/styles/global/theme';

const FormWrapper = styled.div``;

export const Form = styled.form``;

export const FormHead = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 91px;
`;

export const FormHeadWithSmallerMargin = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 65px;
`;

export const FormIcon = styled.div`
  margin-right: 10px;
`;

export const FormBody = styled.div`
  margin-bottom: 60px;
`;

export const FormFoot = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.geofenceMapCard &&
    `
    margin-top:20px;

    @media ${device.large} {
      margin-right:20px;
  }
  `};
`;

export const FormActions = styled.div`
  a + a {
    margin-left: 10px;
  }
  //we need both of the following margins or else the spinner becomes off center
  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const FormLegend = styled.span`
  font-family: ${theme.font};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #${colors.haze};
`;

export const FormTitle = styled.h4`
  font-weight: 700;
  letter-spacing: 0.01em;
  color: ${colors.midnight};
  margin-bottom: 0;
`;

export const FormMessage = styled.div`
  display: none;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 25px;
  margin-top: 20px;
  margin-bottom: -45px;
  background-color: rgba(255, 0, 10, 0.16);
  color: ${colors.midnight};

  ${props =>
    props.message &&
    `
    display: block;
  `};
`;

export const ModalFormMessage = styled.div`
  display: none;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 25px;
  margin-top: -25px;
  margin-bottom: 30px;
  background-color: rgba(255, 0, 10, 0.16);
  color: ${colors.midnight};

  ${props =>
    props.message &&
    `
    display: block;
  `};
`;

export const FormRow = styled.div`
  margin-bottom: 26px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FormControls = styled.div`
  position: relative;
`;

export const FormLabel = styled.label`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  font-family: ${theme.font};
  font-size: 15px;
  font-weight: 400;
  color: ${colors.haze};
  pointer-events: none;
`;

export const FileLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  vertical-align: middle;
  height: 60px;
  font-family: ${theme.font};
  font-size: 15px;
  font-weight: 400;
  color: ${colors.haze};
  pointer-events: none;
  float: left;
  margin-top: 10px;

  @media ${device.large} {
    height: 95px;
    margin-left: 10px;
    margin-top: 0;
  }
`;

export const FormAsterisk = styled.span`
  color: ${colors.cherry};
`;

export const FormCols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -23px;
`;

export const FormCol = styled.div`
  max-width: 100%;
  min-width: 300px;
  flex: 1 100%;
  flex: wrap;

  ${props =>
    props.noMinWidth &&
    `
    min-width: 0px;
    @media ${device.large} {
      min-width: 0px;
    }
    `};
`;

export const FormField = styled.input`
  display: block;
  width: 100%;
  padding: 0 0 13px;
  border: none;
  border-bottom: 1px solid rgba(138, 144, 156, 0.25);

  ${props =>
    props.autocomplete &&
    `
		padding: 0 0 13px 25px;
	`};
`;

export const FormLabelAnimated = styled.label`
  display: block;
  position: relative;
  left: 0;
  top: 0;
  margin-bottom: -14px;
  width: 100%;
  height: 16px;
  font-family: ${theme.font};
  font-size: 24px;
  font-weight: 400;
  color: ${colors.haze};
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s;

  @media ${device.large} {
    font-size: 14px;
    ${props =>
      props.animated &&
      `
        font-size: 11px;
    		top: -15px;
    	`};

    ${props =>
      props.animated &&
      typeof props.offset === 'number' &&
      `
        top: -${props.offset}px;
      `};
  }

  ${props =>
    props.animated &&
    `
    font-size: 15px;
		top: -15px;
	`};

  ${props =>
    props.animated &&
    typeof props.offset === 'number' &&
    `
      top: -${props.offset}px;
    `};

  ${props =>
    props.prefixIcon &&
    `
      margin-left: 20px;
      `};

  ${props =>
    props.firmware &&
    `
        margin-top: 30px;
      `};

  ${props =>
    props.config &&
    `
        margin-top: 70px;
      `};
`;

export const FormFieldAnimated = styled.input`
  display: block;
  position: relative;
  width: 100%;
  padding: 5px 8px 2px 0px;
  border: none;
  border-bottom: 1px solid rgba(138, 144, 156, 0.25);
  background-color: transparent;
  font-size: 22px;
  font-weight: 700;
  color: ${colors.midnight};

  @media ${device.large} {
    font-size: 14px;
    ${props =>
      props.invalid &&
      `
        border-bottom: 1px solid ${colors.midnight};
        `};
  }

  ${props =>
    props.geofenceCard &&
    `font-size: 18px;
    @media ${device.large} {
      font-size: 14px;
    }
    `};

  ${props =>
    props.invalid &&
    `
  border-bottom: 1px solid ${colors.midnight};
  `};

  ${props =>
    props.prefixIcon &&
    `
    padding-left: 22px;
    `};

  ${props =>
    props.suffixIcon &&
    `
    padding-right: 22px;
    padding-bottom:5px;
    @media ${device.large} {
      padding-right: 22px;
    }
    `};

  ${props =>
    props.smallerTextMobile &&
    `
    font-size: 16px;
    @media ${device.medium} {
      font-size: 22px;
    }
    @media ${device.large} {
      font-size: 14px;
    }

	`};

  ${props =>
    props.configName &&
    `
    font-size: 16px;
    @media ${device.large} {
      font-size: 14px;
    }

	`};

  ${props =>
    (props.disableUnderline || props.disabled) &&
    `
    border-bottom: none;
    `};

  ${props =>
    props.maintenanceModal &&
    `

    overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

	`};
`;

export const FieldError = styled.div`
  color: ${colors.cherry};
  font-size: 12px;
  margin-top: 4px;
  min-height: 14px;
  margin-bottom: 12px;
  white-space: break-spaces;
  ${props =>
    props.maintenanceEvents &&
    `
    grid-column: span 1;
    margin-bottom:20px;
    @media ${device.large} {
      grid-column: span 2;
  }
    `};

  ${props =>
    props.topSpacing &&
    `
    margin-top:22px
    `};

  ${props =>
    props.snugSpacing &&
    `
      margin-top:-15px;
      `};
`;

export const FormGridRow = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.large} {
    grid-template-areas: 'FormCol FormCol';
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
  }
`;

export const FormSingleColumnGridRow = styled.div`
  margin-bottom: 20px;
  min-width: 325px;
  display: grid;
  &:last-child {
    margin-bottom: 0;
  }
  @media ${device.large} {
    min-width: 600px;
  }
  ${props =>
    props.help &&
    `
    display:block;`}
`;

export const ChangePasswordFormRow = styled.div`
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const AvatarSourceContainer = styled.div`
  width: 300px;
  height: 300px;
  margin: 0px auto 30px auto;
`;

export const NonTextFieldContainer = styled.div`
  margin: 45px 0 15px 0;

  @media ${device.large} {
    margin: 35px 0 15px 0;
  }
`;

export const FormReadOnlyValue = styled.label`
  display: block;
  position: relative;
  margin-top: 5px;
  font-size: 22px;
  font-weight: 700;
  color: ${colors.midnight};

  @media ${device.large} {
    font-size: 14px;
  }
`;

export const VehicleLicenseWrapper = styled.div`
  width: 60%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;

  ${props =>
    props.licenseState &&
    `
    width: 30%;
    float: right;
    `};
`;

export const MobileVehicleDeviceWrapper = styled.div`
  margin-bottom: 70px;
`;

export const DescriptionTextArea = styled.textarea`
  resize: none;
  width: 100%;
  max-width: 100%;
  margin-top: 30px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @media ${device.large} {
    margin-top: 20px;
  }

  ${props =>
    props.vehicleNotes &&
    `
    height: 100px;
    border-color:${colors.haze};
    `};
`;

export const AttachFileLabel = styled.label`
  display: block;
  position: relative;
  width: 100%;
  height: 16px;
  z-index: 5;
  font-family: ${theme.font};
  font-size: 22px;
  font-weight: 400;
  color: ${colors.haze};
  pointer-events: none;

  @media ${device.large} {
    font-size: 18px;
  }
`;

export const SimNotActive = styled.div`
  display: flex;
  font-family: ${theme.font};
  font-size: 18px;
  font-weight: 400;
  padding: 5px 5px 2px 5px;
  margin: 10px 0 20px 0;
  background: ${colors.concrete};
`;

export const SimMessage = styled.span`
  margin-left: 10px;
`;

export const CalculateDiv = styled.div`
  margin-top: -10px;
  ${props =>
    props.heatmap &&
    `
    margin-bottom: 20px;
    `};
`;

export const HeatmapFilterFormDiv = styled.div`
  display: grid;
  grid-gap: 20px;

  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.large} {
    grid-template-areas: 'FormCol FormCol';
    grid-template-columns: 180px 180px;
    grid-gap: 20px;
  }
`;

export const CheckboxTextSpan = styled.span`
  margin-left: 5px;
  font-size: 22px;
  color: ${colors.haze};
  @media ${device.large} {
    font-size: 15px;
  }
`;

export const AssociatedGroupsDiv = styled.div`
  margin-top: 30px;
  min-height: 150px;
`;

export const FormSectionLabel = styled.div`
  color: ${colors.haze};
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 18px;

  ${props =>
    props.gtt &&
    `
    margin-bottom: 10px;
    `};
  ${props =>
    props.markCompleted &&
    `
    margin-bottom: 5px;
    `};
  ${props =>
    props.maintenance &&
    `
          margin-bottom: 0px;
          `};
`;

export const CurrentlyAssociatedGroupsLabel = styled.div`
  color: ${colors.haze};
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
`;

export const AddGroupContainerDiv = styled.div`
  display: flex;
`;

export const EmailOptionDiv = styled.div`
  margin-top: 20px;
  ${props =>
    props.userPage &&
    `
    margin-top: 40px;
    `};
`;

export const DateRangeValidationDiv = styled.div`
  font-size: 12px;
  margin-top: 20px;
  color: ${colors.cherry};

  @media ${device.large} {
    margin-top: 5px;
  }
`;

export const OrganizationDateDiv = styled.div`
  margin-bottom: 30px;
`;

export const NotesDiv = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  font-family: ${theme.font};
  font-size: 16px;
  font-weight: 400;
  color: ${colors.haze};

  @media ${device.large} {
    font-size: 14px;

    ${props =>
      props.vehicleEdit &&
      `
    margin-bottom: 5px;
    `};
  }
`;

export const DateRangeControlsDiv = styled.div`
  margin-bottom: 0px;
  display: grid;
  grid-template-rows: 80px 90px 80px;
  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol';

  @media ${device.largeAnalytics} {
    margin-bottom: 10px;
    grid-template-areas: 'FormCol FormCol FormCol ';
    grid-template-columns: 180px 180px 100px;
    grid-template-rows: 60px;
    grid-gap: 20px;
  }

  ${props =>
    props.analyticsQuery &&
    `
    margin-bottom: 20px;
  display: grid;
  grid-template-rows: 100px 100px 80px 80px 100px;

  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol'
    'FormCol'
    'FormCol';

  @media ${device.largeAnalytics} {
    grid-template-areas: 'FormCol FormCol FormCol FormCol FormCol';
    grid-template-columns: 180px 180px 200px 200px 100px;
    grid-template-rows: 60px;
    grid-gap: 20px;
  }
    `};
`;

export const HaasStatus = styled.p`
  font-size: 14px;
  margin-top: 5px;
  color: ${colors.charcoal};

  @media ${device.large} {
    font-size: 12px;
  }
`;

export const HassIntegrationWrapperDiv = styled.div`
  margin-top: 15px;

  @media ${device.large} {
    margin-top: 0px;
  }
`;

export const AddVehiclesSelectWrapper = styled.div`
  margin-bottom: 40px;
`;

export const AddVehiclesFiltersWrapper = styled.div`
  margin-bottom: 0px;
  display: grid;
  grid-template-rows: 80px 80px 80px 80px;
  grid-template-areas:
    'Col'
    'Col'
    'Col'
    'Col';

  @media ${device.medium} {
    margin-bottom: 10px;
    grid-template-areas: 'Col Col Col Col ';
    grid-template-columns: 150px 80px 150px 150px;
    grid-template-rows: 60px;
    grid-gap: 20px;
  }
`;

export const UserSelectWrapper = styled.div`
  margin-bottom: 50px;
`;
export const OptInFeatureWrapper = styled.div`
  margin-bottom: 5px;
`;

export const AssignCancelDiv = styled.div`
  float: right;
  margin-top: 50px;

  ${props =>
    props.withTopSpacing &&
    `
    margin-top: 50px;
    `};
`;

export const ReadOnlyFormDisplayDiv = styled.div`
  margin-bottom: 45px;

  height: 16px;
  font-family: ${theme.font};
  font-size: 22px;
  font-weight: 700;
  color: ${colors.midnight};

  @media ${device.large} {
    margin-top: 12px;
    font-size: 14px;
    margin-bottom: 25px;
  }
`;

export default FormWrapper;
