import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { checkForUserPermission } from 'core/redux/user/selectors';
import CreateGroupButton from 'features/fleet/groups/components/buttons/CreateGroupButton';
import DeleteGroupModal from 'features/fleet/groups/components/modals/DeleteGroupModal';
import RenameGroupModal from 'features/fleet/groups/components/modals/RenameGroupModal';
import ResetGroupVehiclesModal from 'features/fleet/groups/components/modals/ResetGroupVehiclesModal';
import { useFetchGroupsQuery } from 'features/fleet/groups/hooks/useFetchGroupsQuery';
import { Link } from 'react-router-dom';
import colors from 'shared/constants/colors';
import { permissionData } from 'shared/constants/users';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import { TableSpinner } from 'shared/styles/components/Spinner';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import GroupListActionMenu from 'features/fleet/groups/components/menus/GroupListActionMenu';
import VehicleCountColumn from 'features/fleet/groups/components/table/VehicleCountColumn';
import { useMemo } from 'react';
import React from 'react';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import ListingCount from 'shared/components/ListingCount';

const GroupsListComponent = props => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [listingCount, setListingCount] = useState(groups?.length);

  useEffect(() => {
    if (groups) {
      groups.forEach(group => {
        group.groupNameDisplay = group.group_name;
      });
    }
    setListingCount(groups?.length);
  }, [groups]);

  return fetchGroupsQuery.isFetched && groups ? (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Groups</PageListTitle>
          <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
            <CreateGroupButton />
          </PermissionProtectedElement>
        </PageListHead>

        <ListingCount
          listingCount={listingCount}
          totalCount={groups?.length}
          itemTypeName={'Group'}
        />

        <SortablePaginatedTable
          className="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableData={groups ? groups : []}
          columns={[
            {
              title: 'Group Name',
              dataIndex: 'groupNameDisplay',
              key: 'a',
              searchable: true,
              className: 'name',
              width: '45%',
              render(value, group) {
                return (
                  <span className="vehicleNameAndIcon" title={`Group Name: ${group.group_name}`}>
                    <IconSvgComponent svgFileName={'folder'} alt="Groups" />

                    <Link
                      to={`${encodeURIComponent(group.group_name)}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {value}
                    </Link>
                  </span>
                );
              },
            },

            {
              title: 'Total Number of Vehicles',
              dataIndex: 'vehicle_count',
              key: 'vehicle_count',
              searchable: true,
              width: '45%',
              render(value) {
                return <VehicleCountColumn vehicleCount={value} />;
              },
            },
            {
              title: 'action',
              dataIndex: '',
              className: 'action',
              key: 'action',
              width: '5%',
              render(group) {
                return <GroupListActionMenu group={group} />;
              },
            },
          ]}
          rowKey={record => record.group_id}
          setListingCount={setListingCount}
          emptyText="No groups are currently associated with this organization"
        />
      </PageListWrapper>
    </>
  ) : (
    <LoadingOverlay />
  );
};

export default GroupsListComponent;
