import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { lockVehicles, unlockVehicles } from 'core/api/vehicles';

import {
  lockVehicleError,
  lockVehicleSuccess,
  unlockVehicleError,
  unlockVehicleSuccess,
  lockAllVehiclesSuccess,
  unlockAllVehiclesSuccess,
  lockAllVehiclesError,
  unlockAllVehiclesError,
  lockAllVehiclesPartialError,
  unlockAllVehiclesPartialError,
  lockError,
} from 'features/fleet/vehicles/helpers/notifications';
import { useDispatch } from 'react-redux';

export const LOCK_OPERATION = {
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
};

// If vehicles is an array, map over it and return an array of vehicle_ids
// else vehicles is a single object, so return the vehicle_id wrapped in an array
const getParams = vehicles => {
  return Array.isArray(vehicles) ? vehicles.map(v => v.vehicle_id) : [vehicles.vehicle_id];
};

const useLockVehicleMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const lockVehicleMutation = useMutation(
    ['lockVehicleMutation'],
    ({ lockOperation, vehiclesOrVehicle }) => {
      let lockUnlockParams = getParams(vehiclesOrVehicle);

      return lockOperation === LOCK_OPERATION.LOCK
        ? lockVehicles(lockUnlockParams)
        : unlockVehicles(lockUnlockParams);
    },
    {
      onSuccess: (response, vars) => {
        const { lockOperation, vehiclesOrVehicle } = vars;
        const status = response.status;

        // invalidate the vehicles query
        queryClient.invalidateQueries(['fetchVehiclesQuery']);

        // determine if it's a lock or unlock operation
        const isLockOperation = lockOperation === LOCK_OPERATION.LOCK;

        // if vehiclesOrVehicle is an array, it's a lock/unlock all operation
        if (Array.isArray(vehiclesOrVehicle)) {
          // should always be 207 in this case
          if (status !== 207) throw new Error('Unexpected response status code');

          // get status code for each message in the response
          const statusCodes = response.response.message.map(ir => ir.statusCode);

          // check if all status codes are 400 or if none are 400
          const allErrors = statusCodes.every(code => code === 400);
          const noErrors = !statusCodes.some(code => code === 400);

          // determine which notification to dispatch
          const actionToDispatch = allErrors
            ? isLockOperation
              ? lockAllVehiclesError()
              : unlockAllVehiclesError()
            : noErrors
            ? isLockOperation
              ? lockAllVehiclesSuccess()
              : unlockAllVehiclesSuccess()
            : isLockOperation
            ? lockAllVehiclesPartialError()
            : unlockAllVehiclesPartialError();

          // dispatch the notification
          dispatch(actionToDispatch);
        }
        // else we're dealing with a single vehicle success condition
        else {
          // get the vehicle name
          const vehicleName = vehiclesOrVehicle.meta.label;

          // dispatch success notification
          return dispatch(
            isLockOperation
              ? lockVehicleSuccess({ vehicleName })
              : unlockVehicleSuccess({ vehicleName }),
          );
        }
      },
      onError: (err, vars) => {
        const { lockOperation, vehiclesOrVehicle } = vars;
        queryClient.invalidateQueries(['fetchVehiclesQuery']);

        if (Array.isArray(vehiclesOrVehicle)) {
          dispatch(lockOperation === LOCK_OPERATION.LOCK ? lockError({ err }) : lockError({ err }));
        } else {
          const vehicleName = vehiclesOrVehicle.meta.label;
          dispatch(
            lockOperation === LOCK_OPERATION.LOCK
              ? lockVehicleError({ vehicleName })
              : unlockVehicleError({ vehicleName }),
          );
        }
      },
    },
  );

  return lockVehicleMutation;
};

export default useLockVehicleMutation;
