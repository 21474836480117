import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import ManageRecipientGroupsActionMenu from 'app/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/ManageRecipientGroupsActionMenu';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';

const ManageRecipientGroupsSummaryTable = ({ recipientGroups, handleUpdateListingCount }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    recipientGroups?.forEach(group => {
      group.formattedUserDisplayNames = getUserNameDisplayFromUserList(group.recipientGroupMembers);
    });
  }, [recipientGroups]);
  const columns = [
    {
      title: 'Recipient Group Name',
      dataIndex: 'recipientGroupName',
      key: 'recipientGroupName',
      className: 'name',
      width: '30%',
      searchable: true,
      render(value, recipientGroup) {
        return (
          <span className="vehicleNameAndIcon" title={'Recipient Group Name: ' + value}>
            <Link
              to={{
                pathname: `/editrecipientgroup/${encodeURIComponent(
                  recipientGroup.recipientGroupId,
                )}`,
                state: pathname,
              }}
              style={{ textDecoration: 'none' }}
            >
              {value}
            </Link>
          </span>
        );
      },
    },
    {
      title: 'Members',
      dataIndex: 'recipientGroupMembers',
      key: 'recipientGroupMembers',
      className: 'generic',
      searchIndex: 'formattedUserDisplayNames',
      searchable: true,
      render(_, row) {
        return (
          <span title={`Members: ${row.formattedUserDisplayNames}`}>
            {row.formattedUserDisplayNames}
          </span>
        );
      },
    },

    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'a',
      render(recipientGroup) {
        return <ManageRecipientGroupsActionMenu recipientGroup={recipientGroup} />;
      },
    },
  ];

  return (
    <SortablePaginatedTable
      className="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableData={recipientGroups ? recipientGroups : []}
      //need to change the key here, probbably a multivariable key as well to guarentee uniqueness
      rowKey={record => record.groupId}
      emptyText="No recipient groups are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageRecipientGroupsSummaryTable;
