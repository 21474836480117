import { useQueryClient } from '@tanstack/react-query';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import SimpleConfirmationModal from 'shared/ui/modals/SimpleConfirmationModal';
import useEditAlertMutation from 'app/features/alerts/services/reactQuery/mutations/useEditAlertMutation';
import useAlertsSummaryProviderContext from 'app/features/alerts/services/providers/AlertsSummaryProvider/hooks/useAlertsSummaryProviderContext';

const DeactivateAlertModal = ({ modalData: { show, alert, isActive }, handleCloseModal }) => {
  const {
    queryKeys: { alertsQueryKey },
  } = useAlertsSummaryProviderContext();
  const mutation = useEditAlertMutation();
  const actionVerb = isActive ? 'Deactivate' : 'Activate';
  const queryClient = useQueryClient();
  return (
    show && (
      <SimpleConfirmationModal
        handleCloseModal={handleCloseModal}
        isLoading={mutation.isLoading}
        submitHandler={async () => {
          try {
            alert.alertDetails.alertInfo.isActive = !alert?.alertDetails?.alertInfo.isActive;
            let result = await mutation.mutateAsync({
              alert: { ...alert },
            });
          } catch (err) {
            logWcpError(err);
          } finally {
            await queryClient.invalidateQueries({ queryKey: alertsQueryKey });
            handleCloseModal();
          }
        }}
        bodyText={`Do you really want to ${actionVerb} this alert:`}
        boldedItem={`${alert?.alertDetails?.alertInfo?.alertName}?`}
        title={`${actionVerb} Alert`}
        confirmText={actionVerb}
        cancelText="Cancel"
      />
    )
  );
};

export default DeactivateAlertModal;
