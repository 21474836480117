import { Scrollbars } from 'react-custom-scrollbars';

import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  FilterLabel,
  SidebarAside,
  SidebarHideToggle,
  SidebarShowToggle,
} from 'shared/styles/components/SharedMapStyledComponents';
import { VehiclePlaybackDiv } from 'shared/styles/components/VehiclePlayback';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import VehiclePlaybackSidebarPlaybackControls from 'app/features/playback/ui/components/VehiclePlaybackSidebarPlaybackControls/VehiclePlaybackSidebarPlaybackControls';
import VehiclePlaybackRequestForm from 'app/features/playback/ui/components/VehiclePlaybackRequestForm/VehiclePlaybackRequestForm';

const VehiclePlaybackSidebar = () => {
  const {
    actions: { setPlaybackSidebarAction },
    state: {
      playbackSidebar: { isOpen },
    },
  } = useVehiclePlaybackProviderContext();

  return (
    <SidebarAside expanded={isOpen}>
      <Scrollbars
        style={{
          width: isOpen ? '100%' : 0,
          height: '100%',
          zIndex: 1,
          backgroundColor: 'white',
          transition: 'all 0.3s',
          transitionDelay: isOpen ? '-.05s' : '.05s',
        }}
      >
        <VehiclePlaybackDiv>
          <FilterLabel>Vehicle Playback</FilterLabel>

          <SidebarHideToggle onClick={() => setPlaybackSidebarAction({ isOpen: false })}>
            <IconSvgComponent
              svgFileName="hideLiveMapFilter"
              title="Hide playback filter"
              alt="Hide playback filter"
            />
          </SidebarHideToggle>
          <VehiclePlaybackRequestForm />
          <VehiclePlaybackSidebarPlaybackControls />
        </VehiclePlaybackDiv>
      </Scrollbars>

      <SidebarShowToggle
        isOpen={true}
        onClick={() => setPlaybackSidebarAction({ isOpen: !isOpen })}
      >
        {isOpen ? null : (
          <IconSvgComponent
            svgFileName="vehicle-playback-sidebar"
            title="Show playback controls"
            alt="Show playback controls"
          />
        )}
      </SidebarShowToggle>
    </SidebarAside>
  );
};

export default VehiclePlaybackSidebar;
