import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@tanstack/react-query';

//deprecated, use useGenericQuery instead
export const useGenericFetchQuery = ({ key, initialQueryParams, errorCallback, apiFn }) => {
  // redux
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  // internal state
  const [responseMessage, setResponseMessage] = useState();
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [enabled, setEnabled] = useState(false);

  // react query
  const query = useQuery(
    [key, queryParams],
    () => {
      return apiFn({
        organizationId: filteredOrganizationId,
        ...queryParams,
      });
    },
    {
      enabled,
      onSuccess: ({ response: { message } }) => {
        setResponseMessage(message);
      },
      onError: err => dispatch(errorCallback({ err })),
    },
  );

  return {
    fetch: ({ startDate, endDate }) => {
      setQueryParams({ startDate, endDate });
      setEnabled(true);
    },
    isLoading: query.isFetching,
    data: responseMessage,
  };
};
