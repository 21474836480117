/**
 * @ External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';

/**
 * @ Styled Components
 */
import {
  SearchResultsCount,
  SearchResult,
  SearchResultHead,
  SearchResultIcon,
  SearchResultTitle,
  SearchResultBody,
  SearchResultText,
  SearchResultItem,
} from 'shared/styles/components/SearchResults';

/**
 * @ Components
 */
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

/**
 * Class for search result component.
 *
 * @class      SearchResultComponent (name)
 */
class SearchResultComponent extends Component {
  render() {
    const { category, filteredData, term } = this.props;
    const categoryItems = filteredData.filter((item, index) => category.category === item.category);
    const searchWords = term.split(' ');

    return (
      <SearchResult>
        <SearchResultHead>
          <SearchResultIcon>
            <IconSvgComponent svgFileName={category.icon_path} />
          </SearchResultIcon>

          <SearchResultTitle small>{category.title}</SearchResultTitle>

          <SearchResultsCount small>{categoryItems.length}</SearchResultsCount>
        </SearchResultHead>

        <SearchResultBody>
          {categoryItems &&
            categoryItems.map((item, index) => (
              <SearchResultItem key={index}>
                <SearchResultTitle>
                  <Highlighter
                    highlightClassName="text-highlight"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={item.title}
                  />
                </SearchResultTitle>

                <SearchResultText>
                  <Highlighter
                    highlightClassName="text-highlight"
                    searchWords={searchWords}
                    autoEscape={true}
                    textToHighlight={item.desc}
                  />
                </SearchResultText>
              </SearchResultItem>
            ))}
        </SearchResultBody>
      </SearchResult>
    );
  }
}

export default connect(state => ({
  filteredData: state.search.filteredData,
  term: state.search.search_term,
}))(SearchResultComponent);
