import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFirmwaresByVehicle } from 'core/api/firmware';
import { updateFetchError } from 'features/ota/firmware/helpers/notifications';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import { checkLogin } from 'shared/utilities/user';
const useFetchFirmwareByVehiclesQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
} = {}) => {
  const dispatch = useDispatch();

  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const [updates, setFirmwareUpdateData] = useState(null);

  // gets firmware updates
  const fetchUpdatesQuery = useQuery(
    ['fetchFirmwareUpdatesByVehicleQuery' + key],
    () => {
      return getFirmwaresByVehicle({ organizationId: filteredOrganizationId });
    },
    {
      enabled: autoRun && checkLogin(),
      onSuccess: response => {
        setFirmwareUpdateData(response.data.message);
      },
      onError: err => dispatch(updateFetchError({ err })),
      refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    },
  );

  return {
    fetchUpdatesQuery,
    updates,
  };
};

export default useFetchFirmwareByVehiclesQuery;
