import { Link, useLocation } from 'react-router-dom';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import ManageAlertsActionMenu from 'app/features/alerts/ui/components/AlertsSummary/menus/ManageAlertsActionMenu';
import { getAggregateAlertMethodFromRecipientList } from 'app/shared/utilities/getAggregateAlertMethodFromRecipientList';
import { getCooldownPeriodLabelFromValue } from 'app/features/alerts/data/ui/dropdown/cooldownPeriodDropdownData';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import { getDelayLabelFromValue } from 'app/features/alerts/data/ui/dropdown/delayDropdownOptions';

const ManageAlertsSummaryTable = ({ alerts, handleUpdateListingCount }) => {
  const { pathname } = useLocation();
  const getAlertNameClasses = alert => {
    return `vehicleNameAndIcon ${
      alert.alertDetails.alertInfo.isActive && alert.alertDetails.alertInfo.testModeEnabled !== true
        ? ''
        : 'deactivated'
    }`;
  };
  const getAlertNameTooltip = alert => {
    return `Alert Name: ${alert.alertDetails.decorated.summaryTable.alertName} ${
      alert.alertDetails.alertInfo.testModeEnabled ? ' (TEST MODE) ' : ''
    }
    ${alert.alertDetails.alertInfo.isActive ? '' : '(Deactivated)'}`;
  };
  const columns = [
    {
      title: 'Alert Name',
      dataIndex: 'alertDetails.decorated.summaryTable.alertName',
      key: 'alertName',
      className: 'name',
      width: '20%',
      searchable: true,
      render(value, alert) {
        return (
          //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
          <span className={getAlertNameClasses(alert)} title={getAlertNameTooltip(alert)}>
            <TruncatedTextWithWrap>
              <Link
                to={{
                  pathname: `/editalert/${encodeURIComponent(alert.alertId)}`,
                  state: pathname,
                }}
                style={{ textDecoration: 'none' }}
              >
                {value}
                {alert.alertDetails.alertInfo.testModeEnabled ? ' (TEST MODE) ' : ''}{' '}
                {alert.alertDetails.alertInfo.isActive ? '' : '(Deactivated)'}
              </Link>
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Condition',
      dataIndex: 'alertDetails.decorated.summaryTable.conditionColumn',
      key: 'conditionColumn',
      className: 'generic',
      searchable: true,
      render(value) {
        return <span title={'Condition: ' + value}>{value}</span>;
      },
    },

    {
      title: 'Value',
      dataIndex: 'alertDetails.decorated.summaryTable.valueColumn',
      key: 'activeInactiveDropdownOption',
      className: 'generic',
      searchable: true,
      width: '10%',
      render(value) {
        return <span title={`Value: ${value}`}>{value}</span>;
      },
    },

    {
      title: 'Vehicle/Group',
      dataIndex: 'alertDetails.decorated.targetVehicleOrGroupName',
      key: 'targetVehicleOrGroupName',
      className: 'generic',
      searchable: true,
      width: '10%',
      render(value) {
        return (
          <span title={`Vehicle/Group: ${value} `}>
            <TruncatedTextWithWrap>{value}</TruncatedTextWithWrap>
          </span>
        );
      },
    },

    {
      title: 'Recipients',
      dataIndex: 'alertDetails.decorated.summaryTable.recipientColumn',
      className: 'generic',
      key: 'recipientColumn',
      searchable: true,
      render(value, alert) {
        return (
          //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
          <span
            title={
              alert?.alertNotifications?.length > 1
                ? 'Recipients: (Multi-level) -' +
                  alert.alertDetails.decorated.summaryTable.recipientColumn
                : 'Recipients: ' + alert.alertDetails.decorated.summaryTable.recipientColumn
            }
          >
            <TruncatedTextWithWrap>
              {alert?.alertNotifications?.length > 1 ? '(Multi-level)' : ''} {value}
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },

    {
      title: 'Method',
      dataIndex: 'alertDetails.decorated.summaryTable.methodColumn',
      className: 'generic',
      key: 'alertMethod',
      searchable: true,
      render(value, row) {
        return <span title={`Method: ${value}`}>{value}</span>;
      },
    },

    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'a',
      render(alert) {
        return <ManageAlertsActionMenu alert={alert} />;
      },
    },
  ];

  return (
    <SortablePaginatedTable
      className="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableData={alerts ? alerts : []}
      rowKey={record => record.alertId}
      emptyText="No alerts are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageAlertsSummaryTable;
