import React from 'react';
import { DropdownLink } from 'shared/styles/components/DropdownMenu';

const ViewLogsMenuItem = ({ vehicle }) => {
  return (
    <DropdownLink to={`/analyticsLogs/${encodeURIComponent(vehicle.vehicle_id)}`}>
      View Logs
    </DropdownLink>
  );
};

export default ViewLogsMenuItem;
