import React from 'react';

import Button, { ButtonWithSpinnerContainer } from 'shared/styles/components/Button';
import { ButtonSpinner } from 'shared/styles/components/Spinner';

export default ({
  isLoading,
  confirmText,
  loadingStyleProp,
  notLoadingStyleProp,
  clickHandler,
  disabled,
  title,
  autoFocus,
  containerProp,
  domId,
}) =>
  isLoading ? (
    <ButtonWithSpinnerContainer {...(containerProp ? { [containerProp]: true } : {})}>
      <Button type="submit" id={domId} {...(loadingStyleProp ? { [loadingStyleProp]: true } : {})}>
        {confirmText}
      </Button>
      <ButtonSpinner />
    </ButtonWithSpinnerContainer>
  ) : (
    <>
      <Button
        type="submit"
        id={domId}
        autoFocus={autoFocus}
        disabled={disabled}
        disabledStyleProp={disabled}
        {...(notLoadingStyleProp ? { [notLoadingStyleProp]: true } : {})}
        onClick={clickHandler}
        title={title}
      >
        {confirmText}
      </Button>
    </>
  );
