import { createContext, useEffect } from 'react';

import { useFetchScheduledMaintenanceEventsQuery } from 'app/features/maintenance/hooks/queries/useFetchScheduledMaintenanceEventsQuery';
import { setAllGroupsReducer } from 'app/features/maintenance/providers/reducers/setAllGroupsReducer';
import { setAllMaintenanceEventsReducer } from 'app/features/maintenance/providers/reducers/setAllMaintenanceEventsReducer';
import { setAllVehiclesReducer } from 'app/features/maintenance/providers/reducers/setAllVehiclesReducer';
import { setMaintenanceSummaryFiltersReducer } from 'app/features/maintenance/providers/reducers/setMaintenanceSummaryFiltersReducer';
import { setMaintenanceSummaryListingCountReducer } from 'app/features/maintenance/providers/reducers/setMaintenanceSummaryListingCountReducer';
import { useFetchGroupsQuery } from 'features/fleet/groups/hooks/useFetchGroupsQuery';
import { useFetchVehiclesQuery } from 'features/fleet/vehicles/hooks/useFetchVehiclesQuery';
import LoadingWrapper from 'shared/components/LoadingWrapper';
import useProviderState from 'shared/hooks/useProviderState';

const MaintenanceSummaryContext = createContext(null);

const initialState = {
  allMaintenanceEvents: [],
  allVehicles: [],
  allGroups: [],

  // filters
  selectedGroup: null,
  selectedVehicle: null,
  selectedType: null,
  selectedStatus: null,
  filterOnlyNextScheduledEvent: true,

  // derived state
  filteredMaintenanceEvents: [],

  // counts
  listingCount: 0,
  totalVehiclesNeedingAttentionCount: 0,
  totalCheckEngineCount: 0,
  totalDueForWorkCount: 0,
  totalDueInSoonCount: 0,
};

const actionReducerMap = {
  // setAllMaintenanceEventsAction
  SET_ALL_MAINTENANCE_EVENTS: setAllMaintenanceEventsReducer,
  // setAllVehiclesAction
  SET_ALL_VEHICLES: setAllVehiclesReducer,
  // setAllGroupsAction
  SET_ALL_GROUPS: setAllGroupsReducer,
  // setMaintenanceSummaryFiltersAction
  SET_MAINTENANCE_SUMMARY_FILTERS: setMaintenanceSummaryFiltersReducer,
  // setMaintenanceSummaryListingCountAction
  SET_MAINTENANCE_SUMMARY_LISTING_COUNT: setMaintenanceSummaryListingCountReducer,
};

const MaintenanceSummaryContextProvider = ({ children, routeVehicleId }) => {
  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    apiFlags: {
      addons: true,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: true,
      obd: true,
    },
  });
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [fetchMaintenanceQuery, maintenanceEvents] = useFetchScheduledMaintenanceEventsQuery();

  const [state, actions] = useProviderState({
    actionReducerMap,
    initialState: {
      ...initialState,
      routeVehicleId,
    },
  });

  // todo - this calls the actions multiple times one for each state change from react query
  useEffect(() => {
    actions.setAllVehiclesAction({ allVehicles: vehicles });
    actions.setAllGroupsAction({ allGroups: groups });
    actions.setAllMaintenanceEventsAction({ allMaintenanceEvents: maintenanceEvents });
    // by default always filter these with the checkbox checked
    actions.setMaintenanceSummaryFiltersAction({
      filterOnlyNextScheduledEvent: state.filterOnlyNextScheduledEvent,
    });
  }, [vehicles, groups, maintenanceEvents]);

  useEffect(() => {
    if (vehicles && routeVehicleId) {
      actions.setMaintenanceSummaryFiltersAction({
        selectedVehicle: vehicles.find(v => v.meta.vehicle_id === routeVehicleId),
      });
    }
  }, [routeVehicleId, vehicles]);

  return (
    <LoadingWrapper
      isLoading={
        fetchMaintenanceQuery.isFetching ||
        fetchVehiclesQuery.isFetching ||
        fetchGroupsQuery.isFetching
      }
    >
      <MaintenanceSummaryContext.Provider
        value={{
          state,
          actions,
        }}
      >
        {children({ state, actions })}
      </MaintenanceSummaryContext.Provider>
    </LoadingWrapper>
  );
};

export default MaintenanceSummaryContextProvider;
