import { SORT_ORDER } from 'shared/constants/table';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

const MaintenanceLogsTable = ({ records, handleSetListingCount }) => {
  const columns = [
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      searchIndex: 'formattedTime',
      key: 'completed_date',
      width: '15%',
      searchable: true,
      render(_, maintenanceLog) {
        return (
          <span title={'Timestamp: ' + maintenanceLog.formattedTime}>
            {maintenanceLog.formattedTime}
          </span>
        );
      },
    },
    {
      title: 'Odometer',
      dataIndex: 'completed_odometer',
      key: 'completed_odometer',
      searchIndex: 'odometerDisplay',
      searchable: true,
      render(_, maintenanceLog) {
        return (
          <span title={'Odometer: ' + maintenanceLog.odometerDisplay}>
            {maintenanceLog.odometerDisplay}
          </span>
        );
      },
    },
    {
      title: 'Engine Hours',
      dataIndex: 'completed_engine_hours',
      key: 'completed_engine_hours',
      searchIndex: 'engineHoursDisplay',
      searchable: true,
      render(_, maintenanceLog) {
        return (
          <span title={'Engine Hours: ' + maintenanceLog.completed_engine_hours}>
            {maintenanceLog.completed_engine_hours}
          </span>
        );
      },
    },
    {
      title: 'Interval',
      dataIndex: 'interval_unit',
      key: 'interval_unit',
      searchIndex: 'intervalDisplay',
      searchable: true,
      render(_, maintenanceLog) {
        return (
          <span title={'Interval: ' + maintenanceLog.intervalDisplay}>
            {maintenanceLog.intervalDisplay}
          </span>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'maintenance_type',
      key: 'maintenance_type',
      searchIndex: 'maintenanceTypeDisplay',
      searchable: true,
      render(_, maintenanceLog) {
        return (
          <span title={'Type: ' + maintenanceLog.maintenanceTypeDisplay}>
            {maintenanceLog.maintenanceTypeDisplay}
          </span>
        );
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      searchIndex: 'notesDisplay',
      searchable: true,
      width: '35%',
      render(_, maintenanceLog) {
        return (
          <span title={'Notes: ' + maintenanceLog.notesDisplay}>
            <TruncatedTextWithWrap>{maintenanceLog.notesDisplay}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Marked Completed By',
      dataIndex: 'markedCompletedByDisplay',
      key: 'markedCompletedByDisplay',
      searchIndex: 'markedCompletedByDisplay',
      searchable: true,
      width: '15%',
      render(_, maintenanceLog) {
        return (
          <span title={'Marked Completed By: ' + maintenanceLog.markedCompletedByDisplay}>
            <TruncatedTextWithWrap>{maintenanceLog.markedCompletedByDisplay}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
  ];

  return (
    <SortablePaginatedTable
      className="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableData={records || []}
      rowKey={record => record.timestamp + record.type + record.odometer}
      emptyText="No maintenance logs are currently associated with this vehicle"
      defaultSortOrder={SORT_ORDER.DESC}
      setListingCount={handleSetListingCount}
    />
  );
};

export default MaintenanceLogsTable;
