/**
 * @ External Dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

/**
 * @ Styled Components
 */
import SearchContainer, {
  SearchResultsHead,
  SearchResultsTitle,
  SearchResultsBody,
  SearchResultsEntry,
  SearchResultsText,
  SearchResultsCount,
} from 'shared/styles/components/SearchResults';

/**
 * @ Components
 */
import SearchResultComponent from 'core/widgets/organizationFilter/SearchResultComponent';

/**
 * Class for search results component.
 *
 * @class      SearchResultsComponent (name)
 */
class SearchResultsComponent extends Component {
  render() {
    const { categories, filteredData, pushed } = this.props;

    return (
      <SearchContainer pushed={pushed}>
        <SearchResultsHead>
          <SearchResultsTitle>
            Search results <SearchResultsCount>{filteredData.length}</SearchResultsCount>
          </SearchResultsTitle>

          <SearchResultsEntry>
            <SearchResultsText>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </SearchResultsText>
          </SearchResultsEntry>
        </SearchResultsHead>

        <SearchResultsBody>
          {categories.map((category, index) => (
            <SearchResultComponent category={category} key={index} />
          ))}
        </SearchResultsBody>
      </SearchContainer>
    );
  }
}

export default connect(state => ({
  categories: state.search.categories,
  filteredData: state.search.filteredData,
}))(SearchResultsComponent);
