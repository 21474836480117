import {
  baseApiUrlDev,
  baseApiUrlProd,
  baseApiUrlStaging,
  baseApiUrlTest,
  baseApiUrlApp,
} from 'shared/constants/api';

export const getEnv = () => {
  const host = window && window.location.host;
  return host.split('.')[0];
};

const apiUrl = (() => {
  const host = window && window.location.host;

  // default localhost to dev but this could be changed
  if (!host || host.includes('localhost:')) {
    return baseApiUrlDev;
  }

  if ('cloud.whelen.com' === host) {
    return baseApiUrlProd;
  }

  // this should return the url in the format https://{baseapiurl}/{stackname} (i.e. https://api.firstresponder.ai/NAME)
  // optionally you can comment out the following line in favor of the one after it in order to test on mobile via local server
  return baseApiUrlApp + getEnv();
  //return baseApiUrlDev;
})();

export default apiUrl;
