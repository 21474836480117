import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { deleteMaintenanceApi } from 'app/features/maintenance/api/delete/deleteMaintenanceApi';
import {
  maintenanceDeleteError,
  maintenanceDeleteSuccess,
} from 'app/features/maintenance/utilities/notifications';

export const useDeleteMaintenanceEventMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const deleteMaintenanceEventMutation = useMutation(
    ['deleteMaintenanceEventMutation'],
    ({ maintenanceEventId }) =>
      deleteMaintenanceApi({
        organizationId: filteredOrganizationId,
        maintenanceEventId: maintenanceEventId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchScheduledMaintenanceEventsQuery']),
          dispatch(maintenanceDeleteSuccess());
      },
      onError: err => {
        dispatch(maintenanceDeleteError());
        logWcpError(err);
      },
    },
  );

  return deleteMaintenanceEventMutation;
};
