import { useEffect, useState } from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { getOrganization } from 'core/redux/organizations/selectors';
import { checkForUserPermission, selectUserRole } from 'core/redux/user/selectors';
import { SimBanner } from 'features/fleet/vehicles/components/forms/custom/SimBanner';
import VehicleCard from 'features/fleet/vehicles/components/mobile/VehicleCard';
import DeleteVehicleModal from 'features/fleet/vehicles/components/modals/DeleteVehicleModal';
import RemoveVehicleFromGroupModal from 'features/fleet/vehicles/components/modals/RemoveVehicleFromGroupModal';
import ResetVehicleModal from 'features/fleet/vehicles/components/modals/ResetVehicleModal';
import ToggleLockVehicleModal from 'features/fleet/vehicles/components/modals/ToggleLockVehicleModal';
import { LOCK_OPERATION } from 'features/fleet/vehicles/hooks/useLockVehicleMutation';
import { permissionData } from 'shared/constants/users';
import { vehicleLockStatusViewData } from 'shared/constants/vehicle';
import { Badge } from 'shared/styles/components/Badge';
import { Cards } from 'shared/styles/components/MobileCard';
import PageListWrapper, {
  IconSpacerSpan,
  PageListActionButtonContainer,
  PageListHead,
} from 'shared/styles/components/PageList';
import { TableSpinner } from 'shared/styles/components/Spinner';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { sizes } from 'shared/utilities/media';
import { getVehicleIcon } from 'shared/utilities/vehicle';

/* TODO - rewire following patterns GroupListComponent to encapsulate action menus and modals */
const VehicleList = ({
  vehicles,
  leftHeaderComponent,
  rightHeaderComponent,
  actionMenuComponent,
  editAccess,
  organization,
  vehiclesFetching,
}) => {
  const navigate = useNavigate();
  // TODO convert these to the new modal pattern below
  const [editVehicleModalData, setEditVehicleModalData] = useState(null);
  const [resetModalData, setResetModalData] = useState(null);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [removeFromGroupModalData, setRemoveFromGroupModalData] = useState(null);
  const [listingCount, setListingCount] = useState(vehicles?.length);
  const [matchingColumns, setMatchingColumns] = useState([]);

  // new modal pattern
  const [toggleLockModalData, setToggleLockModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setListingCount(vehicles?.length);
    setToggleLockModalData(prevState => ({
      ...prevState,
      vehicles,
    }));
    setMatchingColumns(vehicles);
  }, [vehicles]);

  const getActionMenu = (vehicle, commands) => {
    return actionMenuComponent(vehicle, {
      showEditVehicleModal: vehicle => {
        setEditVehicleModalData({ vehicle });
      },
      showToggleLockModal: vehicle => {
        let lockedStatus = vehicle?.device?.CenComCoreAccess;
        setToggleLockModalData(prevState => ({
          ...prevState,
          lockOperation:
            lockedStatus === vehicleLockStatusViewData.UNLOCKED.id
              ? LOCK_OPERATION.LOCK
              : LOCK_OPERATION.UNLOCK,
          // single vehicle
          vehiclesOrVehicle: vehicle,
          show: true,
        }));
      },
      showResetModal: vehicle => {
        setResetModalData({ vehicle });
      },
      showDeleteModal: vehicle => {
        setDeleteModalData({ vehicle });
      },
      showRemoveFromGroupModal: ({ vehicle, group }) => {
        setRemoveFromGroupModalData({ vehicle, group });
      },
    });
  };

  const isSearchMatchingNotes = vehicle => {
    return vehicle.column === 'meta.notes';
  };

  let columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'meta.label',
      className: 'name',
      key: 'a',
      searchable: true,
      width: '15%',
      render(vehicleName, vehicle) {
        return (
          <span
            className="vehicleNameAndIcon"
            title={
              'Vehicle Name: ' +
              (vehicle?.orgdata?.meta?.label ? vehicle?.orgdata?.meta?.label : vehicleName)
            }
          >
            {vehicle.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent
                title={vehicle.meta.out_of_service && 'This vehicle is out of service'}
                svgFileName={
                  vehicle.meta.out_of_service
                    ? 'out-of-service-small'
                    : getVehicleIcon(vehicle.onlineStatus, vehicle.respondingStatus)
                }
              />
            )}

            <PermissionProtectedElement
              requiredPermissions={[permissionData.editfleet]}
              alternativeComponent={<>{vehicleName}</>}
            >
              <Link
                to={{
                  pathname: `/vehicle/${encodeURIComponent(vehicle.vehicle_id)}`,
                }}
                style={{ textDecoration: 'none' }}
              >
                {vehicleName}
              </Link>
            </PermissionProtectedElement>
          </span>
        );
      },
    },
    {
      title: 'VSG Registration ID',
      dataIndex: 'meta.formatted_device_id',
      className: 'connectivity',
      key: 'b',
      searchable: true,
      width: '25%',
      render(value, vehicle) {
        return (
          <>
            <span className="vehicleNameAndIcon">
              <SimBanner vehicle={vehicle} simMessage={false} />
              <TruncatedText
                title={
                  'VSG Registration ID: ' +
                  (vehicle?.orgdata?.meta.formatted_device_id
                    ? vehicle?.orgdata?.meta.formatted_device_id
                    : value)
                }
              >
                {value}
              </TruncatedText>
            </span>
          </>
        );
      },
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'meta.formatted_vehicle_type',
      className: 'type',
      width: '7%',
      key: 'c',
      searchable: true,
      render(value, vehicle) {
        return (
          <span
            title={
              'Vehicle Type: ' +
              (vehicle?.orgdata?.meta.formatted_vehicle_type
                ? vehicle?.orgdata?.meta.formatted_vehicle_type
                : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Vehicle Year',
      dataIndex: 'meta.mfg_year',
      className: 'year',
      width: '5%',
      key: 'h',
      searchable: true,
      render(value, vehicle) {
        return (
          <span
            title={
              'Vehicle Year: ' +
              (vehicle?.orgdata?.meta.mfg_year ? vehicle?.orgdata?.meta.mfg_year : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Vehicle Make',
      dataIndex: 'meta.make',
      className: 'make',
      key: 'f',
      searchable: true,
      render(value, vehicle) {
        return (
          <span
            title={
              'Vehicle Make: ' + (vehicle?.orgdata?.meta.make ? vehicle?.orgdata?.meta.make : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'meta.model',
      className: 'model',
      key: 'g',
      searchable: true,
      render(value, vehicle) {
        return (
          <span
            title={
              'Vehicle Model: ' +
              (vehicle?.orgdata?.meta.model ? vehicle?.orgdata?.meta.model : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'License Plate',
      dataIndex: 'meta.license_plate',
      className: 'plate',
      width: '5%',
      key: 'e',
      searchable: true,
      render(value, vehicle) {
        return (
          <span
            title={
              'License Plate: ' +
              (vehicle?.orgdata?.meta.license_plate ? vehicle?.orgdata?.meta.license_plate : value)
            }
          >
            {value}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'meta.notes',
      className: 'plate',
      key: 'z',
      searchable: true,
      render(value, vehicle) {
        return (
          <>
            {isSearchMatchingNotes(vehicle) ? (
              <IconSvgComponent
                svgFileName={'notes'}
                alt="The current search is matching content in the Notes field of this vehicle"
                title="The current search is matching content in the Notes field of this vehicle"
              />
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Security Lock',
      dataIndex: 'meta.formatted_lock_status',
      key: 'lock',
      searchable: false,
      render: (value, vehicle) => (
        <Badge
          active={vehicle.meta.lock_status === vehicleLockStatusViewData.LOCKED.id}
          unavailable={vehicle.meta.lock_status === vehicleLockStatusViewData.UNAVAILABLE.id}
          na={vehicle.meta.lock_status === vehicleLockStatusViewData.NA.id}
          title="Locked Status"
        >
          {vehicle.meta.formatted_lock_status}
        </Badge>
      ),
    },

    {
      title: 'HAAS Integration',
      dataIndex: 'meta.haas_enabled',
      key: 'k',
      render: (value, vehicle) =>
        vehicle.meta.haas_enabled && (
          <div>
            <IconSvgComponent
              svgFileName={'check-success'}
              alt="HAAS integration is enabled for this vehicle"
              title="HAAS integration is enabled for this vehicle"
            />
            {vehicle.meta.haas_public_alerting &&
              vehicle.meta.haas_public_alerting !== 'ALWAYS_OFF' && (
                <IconSpacerSpan>
                  <IconSvgComponent
                    svgFileName={'haas-alerting'}
                    alt="HAAS alerting is enabled for this vehicle"
                    title="HAAS alerting is enabled for this vehicle"
                  />
                </IconSpacerSpan>
              )}
          </div>
        ),
    },

    {
      title: 'GTT Integration',
      dataIndex: 'meta.gtt_enabled',
      className: 'action',
      key: 'j',
      render: (value, vehicle) =>
        vehicle.meta.gtt_enabled && (
          <IconSvgComponent svgFileName={'check-success'} alt="GTT Enabled" />
        ),
    },
    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'i',
      render: (vehicle, commands) =>
        getActionMenu(vehicle?.orgdata ? vehicle?.orgdata : vehicle, commands),
    },
  ];
  const filterColumns = () => {
    if (!editAccess) {
      let index = columns.findIndex(c => c.key === 'i');
      if (index !== -1) {
        columns.splice(
          columns.findIndex(c => c.key === 'i'),
          1,
        );
      }
    }
    if (organization && !organization.gttEnabled) {
      let index = columns.findIndex(c => c.key === 'j');
      if (index !== -1) {
        columns.splice(
          columns.findIndex(c => c.key === 'j'),
          1,
        );
      }
    }

    if ((organization && !organization.haasEnabled) || !organization.haasSetup) {
      let index = columns.findIndex(c => c.key === 'k');
      if (index !== -1) {
        columns.splice(
          columns.findIndex(c => c.key === 'k'),
          1,
        );
      }
    }

    return columns;
  };

  return !vehicles ? (
    <LoadingOverlay />
  ) : (
    <>
      {vehiclesFetching && <LoadingOverlay />}
      <PageListWrapper>
        <PageListHead>
          <>{leftHeaderComponent}</>
          <>
            <PageListActionButtonContainer>
              <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
                {rightHeaderComponent({})}
              </PermissionProtectedElement>
            </PageListActionButtonContainer>
          </>
        </PageListHead>
        <h5>
          {listingCount === vehicles?.length
            ? `Total of ${listingCount} Vehicle${listingCount !== 1 ? 's' : ''}`
            : `${listingCount} of ${vehicles?.length} Vehicle${vehicles?.length !== 1 ? 's' : ''}`}
        </h5>

        <Media
          queries={{
            tablet: { maxWidth: sizes.tablet },
            mobile: { maxWidth: sizes.mobile },
          }}
        >
          {matches =>
            matches.tablet ? (
              <div>
                <Cards>
                  {vehicles?.map(vehicle => (
                    <VehicleCard
                      vehicle={vehicle}
                      actionMenuComponent={() => (editAccess ? getActionMenu(vehicle) : null)}
                    />
                  ))}
                </Cards>
              </div>
            ) : (
              <div style={styles.vehiclesBody}>
                <SortablePaginatedTable
                  className="table-simple"
                  rowClassName={(record, i) => `row-${i}`}
                  columns={filterColumns()}
                  tableData={vehicles ? vehicles : []}
                  rowKey={record => record.vehicle_id}
                  emptyText="No vehicles are currently associated with this organization"
                  setListingCount={setListingCount}
                  matchingColumns={matchingColumns}
                  setMatchingColumns={setMatchingColumns}
                />
              </div>
            )
          }
        </Media>
      </PageListWrapper>
      <>
        <ToggleLockVehicleModal
          modalData={toggleLockModalData}
          handleCloseModal={() =>
            setToggleLockModalData(prevState => ({ ...prevState, show: false }))
          }
        />

        {resetModalData && (
          <ResetVehicleModal
            vehicle={resetModalData.vehicle}
            onCloseModal={() => setResetModalData(null)}
          />
        )}
        {deleteModalData && (
          <DeleteVehicleModal
            vehicle={deleteModalData.vehicle}
            onCloseModal={() => setDeleteModalData(null)}
          />
        )}
        {removeFromGroupModalData && (
          <RemoveVehicleFromGroupModal
            vehicle={removeFromGroupModalData.vehicle}
            group={removeFromGroupModalData.group}
            onCloseModal={() => setRemoveFromGroupModalData(null)}
          />
        )}
      </>
    </>
  );
};
const styles = {
  vehiclesBody: {
    padding: '0 4px',
  },
};
export default connect(state => ({
  userRole: selectUserRole(state.user.user),
  loadingVehicles: state.vehicles.requestingVehicles,
  editAccess: checkForUserPermission(state.user, permissionData.editfleet),
  organization: getOrganization(state, state.user.filteredOrganizationId),
}))(VehicleList);
