import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { updateGroupHaas } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { updateHaasSuccess } from 'features/fleet/groups/helpers/notifications';

export const useUpdateGroupHaasMutation = ({ groupName }) => {
  const dispatch = useDispatch();
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();

  const updateGroupHaasMutation = useMutation(
    ['updateGroupHaasMutation'],
    ({ groupId, haasIntegrationEnabled, haasPublicAlerting }) =>
      updateGroupHaas({
        organizationId: filteredOrganizationId,
        groupId,
        haasIntegrationEnabled,
        haasPublicAlerting,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchGroupsQuery', 'fetchVehiclesQuery']);
        dispatch(updateHaasSuccess({ groupName }));
      },
      onError: err => {
        logWcpError(err);
      },
    },
  );

  return updateGroupHaasMutation;
};
