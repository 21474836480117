import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { putMaintenanceApi } from 'app/features/maintenance/api/put/putMaintenanceApi';
import {
  suppressAlertsError,
  suppressAlertsSuccess,
} from 'app/features/maintenance/utilities/notifications';

export const useSuppressMaintenanceAlertsMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const suppressAlertsMutation = useMutation(
    ['suppressAlertsMutation'],
    ({ maintenanceEvent }) =>
      putMaintenanceApi({
        organizationId: filteredOrganizationId,
        maintenanceEvent: maintenanceEvent,
        scheduleDuplicate: false,
        isSuppressed: true,
        isCompleted: false,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchScheduledMaintenanceEventsQuery']);
        dispatch(suppressAlertsSuccess());
      },
      onError: err => {
        dispatch(suppressAlertsError());
        logWcpError(err);
      },
    },
  );

  return suppressAlertsMutation;
};
