import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { resetVehicle } from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import {
  vehicleResetError,
  vehicleResetSuccess,
} from 'features/fleet/vehicles/helpers/notifications';

export const useResetVehicleMutation = () => {
  const dispatch = useDispatch();
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const resetVehicleMutation = useMutation(
    ['resetVehicleMutation'],
    ({ vehicleId }) =>
      resetVehicle({
        organizationId: filteredOrganizationId,
        vehicleId,
      }),
    {
      onSuccess: ({ response: { message } }) => {
        dispatch(vehicleResetSuccess({ message }));
      },
      onError: err => {
        logWcpError(err);
        dispatch(vehicleResetError({ err }));
      },
    },
  );

  return resetVehicleMutation;
};
