import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import ProgressBar from 'features/ota/shared/components/ProgressBar';
import { firmwareStatus } from 'features/ota/shared/constants/firmwareStatus';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { permissionData } from 'shared/constants/users';

const FirmwareUpdateTableByDevice = ({
  deviceUpdates,
  vehicleStatuses,
  handleUpdateFirmware,
  updatePostLoading,
  handleUpdateListingCount,
}) => {
  deviceUpdates?.forEach(du => {
    du.fwStatusDisplayName = firmwareStatus[du.firmwareStatus].displayName;
    du.fwStatusProgress = firmwareStatus[du.firmwareStatus].progress;
    du.fwStatusButtonText = firmwareStatus[du.firmwareStatus].buttonText
      ? firmwareStatus[du.firmwareStatus].buttonText
      : null;
  });
  return (
    <>
      <div>
        <SortablePaginatedTable
          className="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableData={deviceUpdates}
          rowKey={record => record.deviceId}
          emptyText="No data to display."
          setListingCount={handleUpdateListingCount}
          columns={[
            {
              title: 'Vehicle Name',
              dataIndex: 'label',
              key: 'a',
              searchable: true,
              render: (value, row) => {
                return (
                  <>
                    <IconSvgComponent
                      svgFileName={
                        vehicleStatuses[row.vehicleId]?.isActive
                          ? vehicleStatuses[row.vehicleId]?.isResponding
                            ? 'car-outline-responding'
                            : 'car-outline-online'
                          : 'car-outline-offline'
                      }
                    />
                    &nbsp;
                    <> {value}</>
                  </>
                );
              },
            },
            {
              title: 'License Plate',
              dataIndex: 'licensePlate',
              key: 'b',
              searchable: true,
              render: licensePlate => {
                return <>{licensePlate}</>;
              },
            },
            {
              title: 'Device Id',
              dataIndex: 'deviceId',
              key: 'c',
              searchable: true,
              render: deviceId => {
                return <>{deviceId}</>;
              },
            },

            {
              title: 'Initial Firmware Version',
              dataIndex: 'versionInitial',
              key: 'd',
              searchable: true,
              render: currentVersion => {
                return <>{currentVersion}</>;
              },
            },
            {
              title: 'Firmware Status',
              dataIndex: 'fwStatusDisplayName',
              key: 'e',
              searchable: true,
              render: fwStatusDisplayName => {
                return <>{fwStatusDisplayName}</>;
              },
            },
            {
              title: 'Update Progress',
              dataIndex: 'fwStatusProgress',
              key: 'f',
              width: '15%',
              render: (fwStatusProgress, row) => {
                if (row.hasNeverStartedOta) {
                  return <></>;
                } else {
                  return <ProgressBar progress={fwStatusProgress} />;
                }
              },
            },
            {
              title: 'Update Action',
              dataIndex: 'fwStatusButtonText',
              className: 'action',
              key: 'g',
              render: (fwStatusButtonText, row) => {
                return fwStatusButtonText !== null ? (
                  <PermissionProtectedElement
                    requiredPermissions={[permissionData.editotafirmware]}
                  >
                    <ButtonWithLoader
                      disabled={
                        !vehicleStatuses[row.vehicleId]?.isActive ||
                        vehicleStatuses[row.vehicleId]?.isResponding
                      }
                      loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                      notLoadingStyleProp={'otaUpdate'}
                      confirmText={fwStatusButtonText}
                      isLoading={row.deviceId === updatePostLoading}
                      clickHandler={async () => {
                        await handleUpdateFirmware(row);
                      }}
                    />
                  </PermissionProtectedElement>
                ) : (
                  <></>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
};
export default FirmwareUpdateTableByDevice;
