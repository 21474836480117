import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { deleteGroupAPI } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useDeleteGroupMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();
  const deleteGroupMutation = useMutation(
    ['deleteGroupMutation'],
    ({ groupId }) =>
      deleteGroupAPI({
        organizationId: filteredOrganizationId,
        groupId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchGroupsQuery']);
      },
      onError: err => {
        logWcpError(err);
      },
    },
  );

  return deleteGroupMutation;
};
