import { useMutation, useQueryClient } from '@tanstack/react-query';

import { editVehicleMetaById } from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useEditVehicleAliasMutation = () => {
  const queryClient = useQueryClient();

  const EditAliasMutation = useMutation(
    ['EditAliasMutation'],

    ({ vehicle }) => editVehicleMetaById(vehicle),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchVehiclesQuery']);
      },
      onError: err => {
        logWcpError(err);
      },
    },
  );

  return EditAliasMutation;
};
