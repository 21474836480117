import { useEffect, useState } from 'react';
import Media from 'react-media';

import AnalyticsLogsCard from 'features/analytics/logs/mobile/AnalyticsLogsCard';
import { AnalyticsCheckboxFilterWrapper } from 'shared/styles/components/Analytics';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import { Cards } from 'shared/styles/components/MobileCard';
import { RespondingDot } from 'shared/styles/components/SharedMapStyledComponents';
import { TableFilterSpan } from 'shared/styles/components/Table';
import ViewVehiclePlayback from 'shared/ui/menus/ViewVehiclePlayback';
import ActionMenu from 'shared/ui/table/ActionMenu';
import SortableTableWithoutPagination from 'shared/ui/table/SortableTableWithoutPagination';
import { sizes } from 'shared/utilities/media';
import { convertDuration, formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import { useSelector } from 'react-redux';
import {
  FIELD_SOLUTION_ENGINEER,
  permissionData,
  userRoleOptions as userRoles,
} from 'shared/constants/users';

const AnalyticsLogsResultsTable = ({ logsQueryResults, selectedVehicle }) => {
  const [vehicleId, setVehicleId] = useState(selectedVehicle);
  const user = useSelector(state => state.user);

  //FSE org permissions live in a different place than other use types (and sys admins can see everything)
  const isViewPlaybackAllowed =
    user.user.userRole === userRoles[FIELD_SOLUTION_ENGINEER].value
      ? user.orgPermissions.permissions.includes(permissionData.viewVehiclePlayback)
      : user.user.permissions.includes(permissionData.viewVehiclePlayback);

  const getActionMenu = ({ timestamp, duration, status }) => {
    const overTwentyFourHours = duration > 86400;
    const offlineEvent = status === 'Off';
    const lessThanFiveSeconds = duration < 5;
    return (
      <ActionMenu>
        <SimpleDropdownItem
          style={
            offlineEvent || overTwentyFourHours || lessThanFiveSeconds || !isViewPlaybackAllowed
              ? { cursor: 'not-allowed' }
              : null
          }
        >
          <ViewVehiclePlayback
            vehicleId={vehicleId}
            timestamp={timestamp}
            duration={duration}
            offlineEvent={offlineEvent}
            overTwentyFourHours={overTwentyFourHours}
            lessThanFiveSeconds={lessThanFiveSeconds}
            isViewPlaybackAllowed={isViewPlaybackAllowed}
          />
        </SimpleDropdownItem>
      </ActionMenu>
    );
  };
  const [filterOfflineEventsCheckboxIsChecked, setFilterOfflineEventsCheckboxIsChecked] = useState(
    false,
  );

  const [filteredReportData, setFilteredReportData] = useState(logsQueryResults.events);

  useEffect(() => {
    if (filterOfflineEventsCheckboxIsChecked) {
      let filteredLogs = logsQueryResults.events.filter(l => l.status !== 'Off');
      setFilteredReportData(filteredLogs);
    } else {
      setFilteredReportData(logsQueryResults.events);
    }
    selectedVehicle && setVehicleId(selectedVehicle.vehicle_id);
  }, [logsQueryResults, filterOfflineEventsCheckboxIsChecked]);

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'a',
      render(value) {
        return (
          <span title={'Timestamp: ' + value}>{formatISOTimestampToLongDateWithTime(value)}</span>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'name',
      key: 'b',
      render(value) {
        return <span title={'Status: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Responding',
      dataIndex: 'responding',
      key: 'c',
      className: 'name',
      render(value) {
        return (
          <span title={'Responding: ' + value}>
            {value ? (
              <span title={'Responding: ' + 'Responding'}>
                <RespondingDot logs>Responding</RespondingDot>
              </span>
            ) : (
              <span title={'Responding: '}></span>
            )}
          </span>
        );
      },
    },

    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'd',
      render(value) {
        return <span title={'Duration: ' + convertDuration(value)}>{convertDuration(value)}</span>;
      },
    },
    {
      title: 'Distance Traveled',
      dataIndex: 'distance_traveled',
      key: 'dt',
      render(value) {
        return (
          <span title={`Distance Traveled: ${value ? `${value} Miles` : 'N/A'}`}>
            {value ? `${value} Miles` : 'N/A'}
          </span>
        );
      },
    },
    {
      title: 'Average Speed',
      dataIndex: 'avg_speed',
      key: 'e',
      render(value) {
        return <span title={'Average Speed: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Top Speed',
      dataIndex: 'top_speed',
      key: 'f',
      render(value) {
        return <span title={'Top Speed: ' + value}>{value}</span>;
      },
    },
    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'g',
      render: ({ timestamp, duration, status }) => getActionMenu({ timestamp, duration, status }),
    },
  ];
  return (
    <>
      <div>
        <AnalyticsCheckboxFilterWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={filterOfflineEventsCheckboxIsChecked}
              onClick={() =>
                setFilterOfflineEventsCheckboxIsChecked(!filterOfflineEventsCheckboxIsChecked)
              }
            />
          </Checkbox>
          <TableFilterSpan>Hide offline events</TableFilterSpan>
        </AnalyticsCheckboxFilterWrapper>
        {filteredReportData && (
          <Media
            queries={{
              tablet: { maxWidth: sizes.tablet },
              mobile: { maxWidth: sizes.mobile },
            }}
          >
            {matches =>
              matches.tablet ? (
                <div>
                  <Cards>
                    {filteredReportData.map(event => (
                      <AnalyticsLogsCard
                        event={event}
                        actionMenuComponent={() =>
                          getActionMenu({
                            timestamp: event.timestamp,
                            duration: event.duration,
                            status: event.status,
                          })
                        }
                      />
                    ))}
                  </Cards>
                </div>
              ) : (
                <div>
                  <SortableTableWithoutPagination
                    className="table-simple"
                    rowClassName={(record, i) => `row-${i}`}
                    columns={columns}
                    data={filteredReportData}
                    rowKey={record => record.timestamp}
                    emptyText="No data to display. Please click calculate to proceed."
                  />
                </div>
              )
            }
          </Media>
        )}
      </div>
    </>
  );
};
export default AnalyticsLogsResultsTable;
