import { Link, useLocation } from 'react-router-dom';

import MaintenanceActionMenu from 'app/features/maintenance/components/MaintenanceSummary/menus/MaintenanceActionMenu';
import {
  determineBoldDisplay,
  determineStatusDisplay,
} from 'app/features/maintenance/utilities/formatting';
import { EventColumn } from 'shared/styles/components/Maintenance';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import { getVehicleIcon } from 'shared/utilities/vehicle';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

const MaintenanceSummaryTable = ({ maintenanceEvents, handleUpdateListingCount }) => {
  const { pathname } = useLocation();
  const columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicleLabel',
      key: 'vehicleLabel',
      className: 'name',
      width: '20%',
      searchable: true,
      render(vehicleName, vehicle) {
        return (
          <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + vehicleName}>
            <IconSvgComponent
              svgFileName={getVehicleIcon(vehicle.onlineStatus, vehicle.respondingStatus)}
            />
            <TruncatedTextWithWrap>
              <Link
                to={{
                  pathname: `/vehicle/${encodeURIComponent(vehicle.vehicleId)}`,
                  state: pathname,
                }}
                style={{ textDecoration: 'none' }}
              >
                {vehicleName}
              </Link>
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Make',
      dataIndex: 'vehicleMake',
      key: 'vehicleMake',
      className: 'make',
      width: '12%',
      searchable: true,
      render(value, row) {
        return (
          <EventColumn due={determineBoldDisplay(row.status)} title={'Make: ' + value}>
            <TruncatedTextWithWrap>{value}</TruncatedTextWithWrap>
          </EventColumn>
        );
      },
    },
    {
      title: 'Odometer',
      dataIndex: 'odometer',
      key: 'odometer',
      searchable: true,
      width: '8%',
      render(value, row) {
        return (
          <EventColumn
            due={determineBoldDisplay(row.status)}
            title={'Odometer: ' + row.odometerDisplay}
          >
            {row.odometerDisplay}
          </EventColumn>
        );
      },
    },
    {
      title: 'Engine Hours',
      dataIndex: 'engineHours',
      key: 'engine_hours',
      searchable: true,
      width: '8%',
      render(value, row) {
        return (
          <EventColumn
            due={determineBoldDisplay(row.status)}
            title={'Engine Hours: ' + row.engineHoursDisplay}
          >
            {row.engineHoursDisplay}
          </EventColumn>
        );
      },
    },
    {
      title: 'Due in',
      dataIndex: 'dueInValue',
      key: 'dueInValue',
      searchIndex: 'dueIn',
      searchable: true,
      width: '10%',
      render(value, row) {
        return (
          <EventColumn
            due={determineBoldDisplay(row.status)}
            title={`Due in: ${value ? row.dueInValueDisplay + ' ' + row.dueInUnit : 'Now'}`}
          >
            {value
              ? `${row.dueInValueDisplay}${row.dueInUnit} ${
                  row.dueInUnit === '%' ? 'oil life remaining' : ''
                }`
              : 'Now'}
          </EventColumn>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'maintenanceTypeDisplay',
      key: 'maintenanceTypeDisplay',
      searchable: true,
      render(value, row) {
        return (
          <EventColumn due={determineBoldDisplay(row.status)} title={'Type: ' + value}>
            {value}
          </EventColumn>
        );
      },
    },
    {
      title: 'Last in',
      dataIndex: 'lastIn',
      key: 'lastIn',
      searchable: true,
      render(value, row) {
        return (
          <EventColumn due={determineBoldDisplay(row.status)} title={'Last in: ' + value}>
            {value}
          </EventColumn>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      searchable: true,
      render(value, row) {
        return determineStatusDisplay(row);
      },
    },
    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'a',
      render(event) {
        return <MaintenanceActionMenu event={event} />;
      },
    },
  ];

  return (
    <SortablePaginatedTable
      className="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableData={maintenanceEvents ? maintenanceEvents : []}
      //need to change the key here, probbably a multivariable key as well to guarentee uniqueness
      rowKey={record => record.event_id}
      emptyText="No maintenance events are currently scheduled for this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default MaintenanceSummaryTable;
