import React from 'react';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const VehicleCountColumn = ({ vehicleCount }) => {
  return (
    <div>
      <IconSvgComponent
        svgFileName={'car-outline'}
        style={styles.totalVehiclesIcon}
        alt="Vehicle"
      />
      <span title={'Total Number of Vehicles: ' + vehicleCount}>{vehicleCount}</span>
    </div>
  );
};

export default VehicleCountColumn;

const styles = {
  totalVehiclesIcon: {
    marginBottom: 5,
    marginRight: 12,
  },
};
