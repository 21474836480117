import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import {
  markMaintenanceCompletedError,
  markMaintenanceCompletedSuccess,
} from 'app/features/maintenance/utilities/notifications';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { putMaintenanceApi } from 'app/features/maintenance/api/put/putMaintenanceApi';

export const useMarkMaintenanceEventCompletedMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const markMaintenanceEventCompletedMutation = useMutation(
    ['markMaintenanceEventCompletedMutation'],
    ({ maintenanceEvent }) =>
      putMaintenanceApi({
        organizationId: filteredOrganizationId,
        maintenanceEvent: maintenanceEvent,
        isSuppressed: false,
        isCompleted: true,
      }),
    {
      onSuccess: (response, vars) => {
        const { maintenanceEvent } = vars;
        queryClient.invalidateQueries(['fetchScheduledMaintenanceEventsQuery']);
        dispatch(markMaintenanceCompletedSuccess(maintenanceEvent.vehicleNameDisplay));
      },
      onError: err => {
        dispatch(markMaintenanceCompletedError());
        logWcpError(err);
      },
    },
  );

  return markMaintenanceEventCompletedMutation;
};
