import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { checkLogin } from 'shared/utilities/user';
import { getMaintenanceApi } from 'app/features/maintenance/api/get/getMaintenanceApi';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { permissionData } from 'shared/constants/users';

export const useFetchScheduledMaintenanceEventsQuery = ({ key = '', autoRun = true } = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const user = useSelector(state => state.user);
  const [scheduledMaintenanceEvents, setScheduledMaintenanceEvents] = useState(null);
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    let allowed = checkForUserPermission(user, permissionData.editfleet);
    setHasPermissions(allowed);
  });

  const scheduledMaintenanceItemsQuery = useQuery(
    ['fetchScheduledMaintenanceEventsQuery' + key],
    () => getMaintenanceApi({ organizationId: filteredOrganizationId }),
    {
      enabled: autoRun && checkLogin() && !!hasPermissions,
      onSuccess: ({ events }) => {
        setScheduledMaintenanceEvents(events);
      },
      onError: err => {
        logWcpError(err);
      },
    },
  );

  return [scheduledMaintenanceItemsQuery, scheduledMaintenanceEvents];
};
