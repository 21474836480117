/**
 * @ External Dependecies
 */
import { createGlobalStyle } from 'styled-components';

import { media } from 'shared/utilities/media';
import colors from 'shared/constants/colors';

export const GlobalTableStyles = createGlobalStyle`

//override last bottom border present on vehicle device table
.rc-table td {
  border-bottom: 0px solid #e9e9e9;
}
	.table-alt {
		color: ${colors.fog};
	}

	.table-alt th {
		padding: 10px 8px 5px;
		background: #fff;
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		color: ${colors.haze};

		&:first-child {
			padding-left: 46px;
		}
	}

	.table-alt td {
		padding: 14px 8px;
		font-size: 14px;
		font-weight: 600;
		color: ${colors.midnight};

		&:first-child {
			padding-left: 46px;
		}

		.vehicleNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.svg-icon {
				margin-right: 10px;
				font-size: 0;
			}
		}
	}

	.table-simple {
		padding-right: 60px;
		margin-right: -60px;
		overflow:inherit;

		${media.tablet_portrait`
			padding-right: 0;
			margin-right: 0;
		`}

		td,
		th {
			white-space: pre-wrap
		}

		th {
			vertical-align: bottom;
			padding: 0 7px 5px 7px;
			background: transparent;
			border-bottom: 1px solid rgba(138, 144, 156, 0.15);
			font-size: 10px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.01em;
			color: ${colors.haze};

			&:first-child {
				padding-left: 0;
			}
		}

		td {
			padding: 24px 7px;
			border-bottom: 1px solid rgba(138, 144, 156, 0.15);
		}

		td.name {
			padding-left: 5;
			font-size: 14px;
			font-weight: 700;
			color: ${colors.midnight};
			min-width:100px;
  			word-break: break-all;
		}

		td.connectivity {
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0.01em;
      		max-width: 0;
      		white-space: nowrap;
      		text-overflow: ellipsis;
      		overflow: hidden;

			.error {
				color: ${colors.cherry};
			}

			.success {
				color: ${colors.midnight};
			}
		}

		td.year,
		td.make,
		td.type,
		td.generic {
			font-size: 14px;
			font-weight: 600;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		td.vin {
			font-size: 10px;
			font-weight: 400;
			text-transform: uppercase;
			color: ${colors.haze};
		}

		td.plate {
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		td.model {
			font-size: 14px;
			font-weight: 400;
			letter-spacing: 0.01em;
			color: ${colors.haze};
		}

		tr:hover {
			background: transparent;
		}

		.vehicleNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.svg-icon {
				margin-right: 13px;
				font-size: 0;
			}
		}
		.deactivated{
			color: ${colors.haze};
		}
		.groupNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			  .svg-icon {
				margin-right: 13px;
				font-size: 0;
			}
		}
		.userNameAndIcon {
			display: flex;
			justify-content: flex-start;
			align-items: left;
			overflow: hidden;
			word-break: break-all;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			.spinner-margin {
				margin: 1px !important;
			}
		}
	}
	.permission-table-simple {
		th {
			vertical-align: top !important;
		}
	}
`;
