import { calculateTotalCounts } from 'app/features/maintenance/utilities/general';

export const setAllMaintenanceEventsReducer = (state, { allMaintenanceEvents }) => {
  // calculate total counts
  let totalCounts = calculateTotalCounts(allMaintenanceEvents);

  return {
    ...state,
    allMaintenanceEvents,
    filteredMaintenanceEvents: allMaintenanceEvents,
    ...totalCounts,
  };
};
