import { useSelector } from 'react-redux';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { useMutation } from '@tanstack/react-query';

const createMutationKey = (apiFn, params) => [
  apiFn.name,
  ...Object.entries(params).map(([key, value]) => `${key}:${value}`),
];

const useGenericMutation = ({ apiFn, onSuccessCallback, onErrorCallback }) => {
  const organizationId = useSelector(state => state.user.filteredOrganizationId);

  const mutationResult = useMutation(
    params => {
      const allParams = { organizationId, ...params };

      // Generate key for tracking or logging
      createMutationKey(apiFn, allParams);

      return apiFn(allParams);
    },
    {
      onSuccess: onSuccessCallback
        ? response => {
            try {
              onSuccessCallback(response);
            } catch (err) {
              // catch error in success callback
              logWcpError('Error in Success Callback', err);
            }
          }
        : () => {
            // default log success
          },
      onError: onErrorCallback
        ? err => {
            try {
              onErrorCallback(err);
            } catch (error) {
              // catch error in error callback
              logWcpError(error);
            }
          }
        : () => {
            logWcpError(err);
          },
    },
  );

  return {
    ...mutationResult,
    getMutationKey: params => createMutationKey(apiFn, { ...params, organizationId }),
  };
};

export default useGenericMutation;
