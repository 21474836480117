import { wcpDebugProviderLog } from 'app/core/error/utilities/ErrorLogger';
import { useCallback } from 'react';
import { useReducer } from 'react';

import {
  createActionDispatchers,
  createActionDispatchersV2,
  generateReducerWithPayload,
} from 'shared/utilities/reducer';

// takes action reducer map and initial state and returns state and action dispatchers
const useProviderState = ({ actionReducerMap, initialState }, v2 = false) => {
  // takes action reducer map and creates a reducer function with payload
  const reducer = generateReducerWithPayload(actionReducerMap);

  // useReducer hook to create state and dispatch function
  const [state, dispatch] = useReducer(reducer, initialState);

  // create action dispatchers from action reducer map
  const actionDispatchers = v2
    ? createActionDispatchersV2(dispatch, actionReducerMap)
    : createActionDispatchers(dispatch, actionReducerMap);

  // wrap action dispatchers in useCallback to memoize
  const actions = useCallback(actionDispatchers, [dispatch]);

  wcpDebugProviderLog('PROVIDER STATE:', { state, actions });

  // return state and actions
  return [state, actions];
};

export default useProviderState;
