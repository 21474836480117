import {
  maintenanceStatusDropdownData,
  maintenanceStatusDropdownOptions,
} from 'app/features/maintenance/data/maintenanceStatusDropdownData';
import {
  maintenanceTypeDropdownData,
  maintenanceTypeDropdownOptions,
} from 'app/features/maintenance/data/maintenanceTypeDropdownData';
import { MaintenanceFilters } from 'shared/styles/components/Maintenance';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';
import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptions';

const MaintenanceSummaryFilters = ({
  vehicles,
  groups,
  selectedVehicle,
  selectedStatus,
  setMaintenanceSummaryFilters,
}) => {
  const defaultSelectedVehicle = vehicles?.find(v => v.vehicle_id === selectedVehicle?.vehicle_id)
    ?.meta.label;
  const defaultSelectedStatus = maintenanceStatusDropdownData?.find(d => d === selectedStatus)
    ?.value;

  const groupOptions = groups?.map(group => ({ label: group.group_name, value: group.group_name }));
  const groupDropdownOptions = new DropdownOptions(groupOptions);

  const vehicleOptions = vehicles?.map(vehicle => ({
    label: vehicle.meta.label,
    value: vehicle.meta.label,
  }));
  const vehicleDropdownOptions = new DropdownOptions(vehicleOptions);

  return (
    <MaintenanceFilters>
      <FilteredDropdown
        fieldLabel={'Group'}
        domId="groupDropdown"
        placeholderLabel={'All Groups'}
        styleOptions={{}}
        setup={{
          dropdownOptions: groupDropdownOptions,
          handleSelect: selectedOption => {
            setMaintenanceSummaryFilters({
              selectedGroup: selectedOption.value
                ? groups.find(g => g.group_name === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Type'}
        domId="typeDropdown"
        placeholderLabel={'All Types'}
        styleOptions={{}}
        setup={{
          dropdownOptions: maintenanceTypeDropdownOptions,
          handleSelect: selectedOption => {
            setMaintenanceSummaryFilters({
              selectedType: selectedOption.value
                ? maintenanceTypeDropdownData.find(t => t.value === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Status'}
        domId="statusDropdown"
        placeholderLabel={'All Statuses'}
        styleOptions={{}}
        setup={{
          dropdownOptions: maintenanceStatusDropdownOptions,
          preselectedValue: defaultSelectedStatus,
          handleSelect: selectedOption => {
            setMaintenanceSummaryFilters({
              selectedStatus: selectedOption.value
                ? maintenanceStatusDropdownData.find(s => s.value === selectedOption.value)
                : null,
            });
          },
        }}
      />
      <FilteredDropdown
        fieldLabel={'Vehicle'}
        domId="vehicleDropdown"
        placeholderLabel={'All Vehicles'}
        styleOptions={{}}
        setup={{
          dropdownOptions: vehicleDropdownOptions,
          preselectedValue: defaultSelectedVehicle,
          handleSelect: selectedOption => {
            console.log(selectedOption);
            setMaintenanceSummaryFilters({
              selectedVehicle: selectedOption.value
                ? vehicles.find(v => v.meta.label === selectedOption.value)
                : null,
            });
          },
        }}
      />
    </MaintenanceFilters>
  );
};

export default MaintenanceSummaryFilters;
