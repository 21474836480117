import { useDeleteVehicleMutation } from 'features/fleet/vehicles/hooks/useDeleteVehicleMutation';
import React from 'react';
import ConfirmationModalWithInnerModalPortal from 'shared/ui/modals/ConfirmationModalWithInnerModalPortal';

const DeleteVehicleModal = ({ vehicle, onCloseModal }) => {
  const deleteVehicleMutation = useDeleteVehicleMutation();

  return (
    <ConfirmationModalWithInnerModalPortal
      noIcon
      onCloseModal={onCloseModal}
      onSuccess={onCloseModal}
      onFailure={onCloseModal}
      bodyText={`Do you really want to delete this vehicle: `}
      boldedItem={`${vehicle.meta.label}?`}
      title={`Delete Vehicle`}
      handleRequestClose={onCloseModal}
      submitHandler={async () => {
        await deleteVehicleMutation.mutateAsync({ vehicleId: vehicle.vehicle_id });
      }}
      confirmText="Delete Vehicle"
      cancelHandler={onCloseModal}
      cancelText="Cancel"
    />
  );
};

export default DeleteVehicleModal;
