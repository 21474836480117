import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { deleteVehicle } from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useDeleteVehicleMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const queryClient = useQueryClient();
  const deleteVehicleMutation = useMutation(
    ['deleteVehicleMutation'],
    ({ vehicleId }) =>
      deleteVehicle({
        organizationId: filteredOrganizationId,
        vehicleId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchVehiclesQuery']);
      },
      onError: err => {
        logWcpError(err);
      },
    },
  );

  return deleteVehicleMutation;
};
