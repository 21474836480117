import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { updateFirmware } from 'core/api/firmware';
import {
  partialUpdateFailure,
  updateFailure,
  updateFirmwareSuccess,
} from 'features/ota/firmware/helpers/notifications';
import { parse207Response } from 'core/api/API';

const useUpdateFirmwareMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const updateFirmwareMutation = useMutation(
    ['updateFirmwareMutation'],
    updateData =>
      updateFirmware({
        ...updateData,
        ...{ organizationId: filteredOrganizationId },
      }),
    {
      onSuccess: response => {
        parse207Response({
          response,
          handleSuccess: () => dispatch(updateFirmwareSuccess()),
          handlePartial: () =>
            dispatch(partialUpdateFailure({ responseMessageArray: response.data.message })),
          handleError: () => dispatch(updateFailure({ err: 'All firmware updates failed' })),
        });
      },
      onError: err => dispatch(updateFailure({ err })),
    },
  );

  return updateFirmwareMutation;
};

export default useUpdateFirmwareMutation;
