import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGroups } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { groupFetchError } from 'features/fleet/groups/helpers/notifications';
import { sortAscendingAlpha } from 'shared/utilities/general';

export const useFetchGroupsQuery = ({ enabled = true } = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();
  const [groups, setGroups] = useState(null);

  const fetchGroupsQuery = useQuery(['fetchGroupsQuery'], () => getGroups(filteredOrganizationId), {
    enabled,
    onSuccess: ({ response: { message: groups } }) => {
      groups = groups
        .sort((g1, g2) => sortAscendingAlpha(g1.group_name, g2.group_name))
        .map(group => ({
          ...group,
          vehicle_count: group.vehicle_id?.length || 0,
        }));
      setGroups(groups);
    },
    onError: err => {
      logWcpError(err);
      dispatch(groupFetchError({ err }));
    },
  });

  return [fetchGroupsQuery, groups];
};
