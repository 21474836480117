import React, { useContext, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';

import { postDisableGeofence, postEnableGeofence } from 'core/api/geofences';
import { addNotification } from 'core/redux/ui/actions';
import { GeofencesApi } from 'features/geofences/components/GeofencesContainer';
import GeofenceCategorySection from 'features/geofences/components/sidebar/GeofenceCategorySection';
import Searchbox from 'shared/components/Searchbox';
import { categoryOptions } from 'features/geofences/constants/dropdownOptions';
import { intersectionCategories } from 'features/geofences/constants/intersectionCategories';
import {
  SwitchCheckbox,
  SwitchCheckboxLabel,
  SwitchTextDiv,
} from 'shared/styles/components/Checkbox';
import {
  GeofenceCardListWrapperDiv,
  GeofencesCategoryFiltersDiv,
  IntersectionsFiltersDiv,
} from 'shared/styles/components/Geofences';
import {
  MapSidebarSearchBoxWrapperDiv,
  SidebarAside,
  SidebarShowToggle,
  LiveMapRightSidebarWrapperDiv,
} from 'shared/styles/components/SharedMapStyledComponents';
import { FilterLabel, SidebarHideToggle } from 'shared/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { GenerateIntersectionsToggle } from 'features/geofences/components/sidebar/GenerateIntersectionsToggle';

const GeofencesSidebarContainer = ({
  dispatchNotification,
  currentOrganizationId,
  isOpen,
  geofencesData,
  intersectionsData,
}) => {
  const { setGeofencesSidebarOpen, reloadGeofencesData } = useContext(GeofencesApi);

  const [searchText, setSearchText] = useState('');
  const [requestComplete, setRequestComplete] = useState(true);

  const filteredGeofencesBySearch = geofencesData.filter(gf =>
    gf.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  let geofencesByCategoryUnsorted = filteredGeofencesBySearch.reduce((gfbc, gf) => {
    gfbc[gf.category] = gfbc[gf.category] ? [...gfbc[gf.category], gf] : [gf];
    return gfbc;
  }, {});

  let geofencesByCategory = Object.keys(geofencesByCategoryUnsorted)
    .sort(function(a, b) {
      return (
        categoryOptions.findIndex(co => co.label === a) -
        categoryOptions.findIndex(co => co.label === b)
      );
    })
    .reduce((obj, key) => {
      obj[key] = geofencesByCategoryUnsorted[key];
      return obj;
    }, {});

  const enableGeofences = async ({ geofenceIds }) => {
    try {
      let response = await postEnableGeofence({
        geofenceIds,
        organizationId: currentOrganizationId,
      });
      if (response) {
        dispatchNotification.enableGeofenceSuccess();
      }
    } catch (err) {
      dispatchNotification.enableGeofenceError({ err });
    }
  };

  const disableGeofences = async ({ geofenceIds }) => {
    try {
      let response = await postDisableGeofence({
        geofenceIds,
        organizationId: currentOrganizationId,
      });
      if (response) {
        dispatchNotification.disableGeofenceSuccess();
      }
    } catch (err) {
      dispatchNotification.disableGeofenceError({ err });
    }
  };

  const generateCheckboxToggleFor = gfc => {
    let checked = geofencesByCategory[gfc] && geofencesByCategory[gfc].some(gf => gf.enabled);

    return (
      <div>
        <SwitchCheckbox
          id={gfc}
          disabled={!geofencesByCategory.hasOwnProperty(gfc)}
          checked={checked}
          onChange={async e => {
            setRequestComplete(false);
            let geofenceIds = geofencesByCategory[gfc].map(gf => gf.geofenceId);

            if (checked) await disableGeofences({ geofenceIds });
            else await enableGeofences({ geofenceIds });
            await reloadGeofencesData();
            setRequestComplete(true);
          }}
        />
        <SwitchCheckboxLabel
          for={gfc}
          disabled={!geofencesByCategory.hasOwnProperty(gfc)}
          title={`Toggle ${gfc} On/Off`}
        >
          <SwitchTextDiv>{gfc}</SwitchTextDiv>
        </SwitchCheckboxLabel>
      </div>
    );
  };
  return (
    <SidebarAside expanded={isOpen}>
      {!requestComplete && <LoadingOverlay />}
      {
        <Scrollbars
          style={{
            width: isOpen ? '100%' : 0,
            height: '100%',
            zIndex: 1,
            backgroundColor: 'white',
            transition: 'all 0.3s',
            transitionDelay: isOpen ? '-.05s' : '.05s',
          }}
        >
          <LiveMapRightSidebarWrapperDiv>
            <FilterLabel>Geofences</FilterLabel>

            <SidebarHideToggle onClick={() => setGeofencesSidebarOpen(false)}>
              <IconSvgComponent
                svgFileName="hideLiveMapFilter"
                title="Hide geofences sidebar"
                alt="Hide geofences sidebar"
              />
            </SidebarHideToggle>
            <GeofencesCategoryFiltersDiv>
              {Object.values(categoryOptions).map(c => (
                <div>{generateCheckboxToggleFor(c.label)}</div>
              ))}
            </GeofencesCategoryFiltersDiv>
            {/* temporarily commenting out geofence intersections until all teams are ready for them */}
            {/* <FilterLabel>Intersections</FilterLabel>
            <IntersectionsFiltersDiv>
              {Object.values(intersectionCategories).map(i => (
                <div>
                  {GenerateIntersectionsToggle(
                    intersectionsData,
                    i.label,
                    setRequestComplete,
                    reloadGeofencesData,
                    currentOrganizationId,
                  )}
                </div>
              ))}
            </IntersectionsFiltersDiv> */}

            <MapSidebarSearchBoxWrapperDiv geofences>
              <Searchbox
                smallSearch={false}
                setSearchText={setSearchText}
                searchText={searchText}
              />
            </MapSidebarSearchBoxWrapperDiv>
            <GeofenceCardListWrapperDiv>
              {Object.keys(geofencesByCategory).map(cat => (
                <GeofenceCategorySection
                  categoryName={cat}
                  matchingGeofences={geofencesByCategory[cat]}
                />
              ))}
            </GeofenceCardListWrapperDiv>
          </LiveMapRightSidebarWrapperDiv>
        </Scrollbars>
      }
      <SidebarShowToggle isOpen={true} onClick={() => setGeofencesSidebarOpen(!isOpen)}>
        {isOpen ? null : (
          <IconSvgComponent
            svgFileName="geofences-sidebar"
            title="Show geofences sidebar"
            alt="Show geofences sidebar"
          />
        )}
      </SidebarShowToggle>
    </SidebarAside>
  );
};

export default connect(
  state => ({
    currentOrganizationId: state.user.filteredOrganizationId,
  }),
  dispatch => ({
    dispatchNotification: {
      enableGeofenceSuccess: () =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, 'Geofences Enabled Successfully'),
          }),
        ),
      disableGeofenceSuccess: () =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, 'Geofences Disabled Successfully'),
          }),
        ),
      enableGeofenceError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, `Error Enabling Geofences`, err),
          }),
        ),
      disableGeofenceError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, `Error Disabling Geofences`, err),
          }),
        ),
    },
  }),
)(GeofencesSidebarContainer);
