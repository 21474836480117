import { groupPath } from 'shared/constants/api';
import { apiCall } from 'core/api/API';

export const getGroups = async organizationId => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: groupPath,
    method: 'get',
    queryStringParameters: {
      ...(organizationId && { organization_id: `${organizationId}` }),
    },
  });
};

export const getGroupById = async ({ groupId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/group/${groupId}`,
    method: 'get',
  });
};

export const createGroup = async ({ groupName, organizationId }) => {
  groupName = groupName.trim();
  return await apiCall({
    endpoint: `cloudfrontAPI`,
    path: `${groupPath}`,
    method: 'post',
    body: {
      organization_id: organizationId,
      group_name: groupName,
      action: 'Create',
    },
  });
};

export const duplicateGroup = async ({ groupName, vehicleIds, organizationId }) => {
  groupName = groupName.trim();
  return await apiCall({
    endpoint: `cloudfrontAPI`,
    path: `${groupPath}`,
    method: 'post',
    body: {
      organization_id: organizationId,
      group_name: groupName,
      vehicle_id: vehicleIds,
      action: 'Duplicate',
    },
  });
};

export const updateGroupAPI = async ({ organizationId, groupId, groupName }) => {
  groupName = groupName.trim();
  return await apiCall({
    endpoint: `cloudfrontAPI`,
    path: `/group/${groupId}`,
    method: 'put',
    body: { organization_id: organizationId, group_id: groupId, group_name: groupName },
  });
};

export const updateGroupHaas = async ({
  organizationId,
  groupId,
  haasIntegrationEnabled,
  haasPublicAlerting,
}) => {
  return await apiCall({
    endpoint: `cloudfrontAPI`,
    path: `/group/${groupId}/haas`,
    method: 'post',
    body: {
      organization_id: organizationId,
      group_id: groupId,
      haas_enabled: haasIntegrationEnabled,
      haas_public_alerting: haasPublicAlerting,
    },
  });
};

export const assignConfirgurationToGroupAPI = async ({ group, selectedConfiguration }) => {
  return await apiCall({
    endpoint: `cloudfrontAPI`,
    path: `/config/update/${selectedConfiguration}/group/${group.group_id}`,
    method: 'post',
  });
};

export const addVehiclesToGroupAPI = async ({ groupId, vehicleIds }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/group/${groupId}/vehicles`,
    method: 'post',
    body: {
      vehicle_id: vehicleIds,
    },
  });
};

export const removeVehiclesFromGroupAPI = async ({ groupId, vehicleIds }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/group/${groupId}/vehicles`,
    method: 'del',
    body: {
      vehicle_id: vehicleIds,
    },
  });
};

export const deleteGroupAPI = async ({ groupId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${groupPath}/${groupId}`,
    method: 'del',
  });
};

export const getVehicleMemberGroups = async ({ vehicles }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: groupPath,
    method: 'get',
  });
};
