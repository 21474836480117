import { useQuery } from '@tanstack/react-query';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { useSelector } from 'react-redux';

const createGenericQueryKeyFactory = (apiFn, queryKeyPrefix) => params => ({
  queryKey: [queryKeyPrefix, ...Object.entries(params).map(([key, value]) => `${key}:${value}`)],
  apiFnCallback: () => apiFn(params),
});

const useGenericQuery = ({
  queryKeyPrefix,
  apiFn,
  params,
  onSuccessCallback = () => {},
  autoRun = true,
}) => {
  const queryFactory = createGenericQueryKeyFactory(apiFn, queryKeyPrefix);

  const organizationId = useSelector(state => state.user.filteredOrganizationId);

  const { queryKey, apiFnCallback } = queryFactory({ ...params, organizationId });

  const queryResult = useQuery(queryKey, apiFnCallback, {
    enabled: autoRun,
    onSuccess: onSuccessCallback
      ? response => {
          try {
            // if onSuccessCallback function exists
            if (typeof onSuccessCallback === 'function') {
              onSuccessCallback(response);
            }
          } catch (err) {
            logWcpError('Error in Success Callback', err);
          }
        }
      : () => {},
    onError: err => {
      logWcpError(err);
    },
  });

  return [queryResult, queryKey];
};

export default useGenericQuery;
