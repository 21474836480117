import { useState, useEffect } from 'react';
import ProgressBar from 'features/ota/shared/components/ProgressBar';
import { firmwareStatus } from 'features/ota/shared/constants/firmwareStatus';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';
import ListingCount from 'shared/components/ListingCount';

const ConfigurationUpdateTable = ({
  deviceUpdates,
  vehicleStatuses,
  handleUpdateConfiguration,
  configUpdatePostLoading,
  updateAllLoading,
}) => {
  deviceUpdates.forEach(du => {
    du.configStatusDisplayName = firmwareStatus[du.config_status].displayName;
    du.configStatusProgress = firmwareStatus[du.config_status].progress;
    du.configStatusButtonText = firmwareStatus[du.config_status].buttonText
      ? firmwareStatus[du.config_status].buttonText
      : null;
  });

  const [listingCount, setListingCount] = useState(deviceUpdates?.length);

  useEffect(() => {
    setListingCount(deviceUpdates?.length);
  }, [deviceUpdates]);

  return (
    <>
      <ListingCount
        listingCount={listingCount}
        totalCount={deviceUpdates.length}
        itemTypeName={'Update'}
      />
      <SortablePaginatedTable
        className="table-simple"
        rowClassName={(record, i) => `row-${i}`}
        tableData={deviceUpdates}
        rowKey={record => record.duid}
        emptyText="No data to display."
        setListingCount={setListingCount}
        columns={[
          {
            title: 'Vehicle Name',
            dataIndex: 'label',
            key: 'a',
            searchable: true,
            render: (value, row) => {
              return (
                <>
                  <IconSvgComponent
                    svgFileName={
                      vehicleStatuses[row.vehicle_id]?.isActive
                        ? vehicleStatuses[row.vehicle_id]?.isResponding
                          ? 'car-outline-responding'
                          : 'car-outline-online'
                        : 'car-outline-offline'
                    }
                  />
                  &nbsp;
                  <> {value}</>
                </>
              );
            },
          },
          {
            title: 'License Plate',
            dataIndex: 'license_plate',
            key: 'b',
            searchable: true,
            render: licensePlate => {
              return <>{licensePlate}</>;
            },
          },
          {
            title: 'Current Config',
            dataIndex: 'current_config_name',
            key: 'd',
            searchable: true,
            render: configName => {
              return <>{configName}</>;
            },
          },
          {
            title: 'Config Status',
            dataIndex: 'configStatusDisplayName',
            key: 'e',
            searchable: true,
            render: configStatusDisplayName => {
              return <>{configStatusDisplayName}</>;
            },
          },
          {
            title: 'Update Progress',
            dataIndex: 'configStatusProgress',
            key: 'f',
            width: '15%',
            render: configStatusProgress => {
              return <ProgressBar progress={configStatusProgress} />;
            },
          },
          {
            title: 'Update Action',
            dataIndex: 'configStatusButtonText',
            className: 'action',
            key: 'g',
            render: (configStatusButtonText, row) => {
              return configStatusButtonText !== null && !updateAllLoading ? (
                <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
                  <ButtonWithLoader
                    disabled={
                      !vehicleStatuses[row.vehicle_id]?.isActive ||
                      vehicleStatuses[row.vehicle_id]?.isResponding
                    }
                    loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                    notLoadingStyleProp={'otaUpdate'}
                    confirmText={configStatusButtonText}
                    isLoading={row.vehicle_id === configUpdatePostLoading}
                    clickHandler={async () => {
                      await handleUpdateConfiguration({
                        configId: row.config_id,
                        vehicleId: row.vehicle_id,
                      });
                    }}
                  />
                </PermissionProtectedElement>
              ) : (
                <></>
              );
            },
          },
        ]}
      ></SortablePaginatedTable>
    </>
  );
};

export default ConfigurationUpdateTable;
