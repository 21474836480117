import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getGroupById as getGroupAPI } from 'core/api/groups';
import { editVehicleAddons, getVehicles as getOrgVehiclesAPI } from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { addNotification } from 'core/redux/ui/actions';
import VehicleAddonFeatures from 'features/fleet/vehicles/components/controls/addons/VehicleAddonFeatures';
import Button from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import PageListWrapper from 'shared/styles/components/PageList';
import {
  BaseVehicleDiv,
  CheckboxTextSpan,
  SelectAllCheckboxDiv,
  SelectAllVehiclesDiv,
  ServerErrorDiv,
  VehicleListDiv,
  VehicleListingDiv,
} from 'shared/styles/components/VehicleAddons';
import { AssignCancelDiv } from 'shared/styles/components/Form';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/legacy/FilteredDropdown';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { sortAscendingAlphaLabel } from 'shared/utilities/vehicle';

const VehicleGroupAddonsPage = ({ organizationId, dispatchNotification }) => {
  const navigate = useNavigate();
  const { groupId } = useParams();

  const [group, setGroup] = useState(null);
  const [orgVehicles, setOrgVehicles] = useState([]);
  const [groupVehicles, setGroupVehicles] = useState([]);
  const [selectedGroupVehicles, setSelectedGroupVehicles] = useState([]);
  const [selectedBaseVehicle, setSelectedBaseVehicle] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [addonValidationErrors, setAddonValidationErrors] = useState(false);
  const [serverError, setServerError] = useState(false);

  const [vehicleAddons, setVehicleAddons] = useState([]);
  // API
  const getGroup = async () => {
    try {
      const {
        response: { message },
      } = await getGroupAPI({ groupId });

      setGroup(message);
    } catch (err) {
      dispatchNotification.getGroupError({ err });
    }
  };
  const getVehicles = async () => {
    try {
      const {
        response: { message },
      } = await getOrgVehiclesAPI({
        organizationId: organizationId,
        apiFlags: {
          addons: true,
          cell: false,
          device: false,
          gps: false,
          meta: true,
          maintenance: false,
          obd: false,
        },
      });

      setOrgVehicles(message);
    } catch (err) {
      dispatchNotification.getOrgVehiclesError({ err });
    }
  };

  // EFFECTS
  useEffect(() => {
    setVehicleAddons(
      selectedBaseVehicle?.addons ? JSON.parse(JSON.stringify(selectedBaseVehicle.addons)) : [],
    );
  }, [selectedBaseVehicle]);

  useEffect(() => {
    getGroup();
  }, []);

  useEffect(() => {
    getVehicles();
  }, [group]);

  useEffect(() => {
    if (group) {
      setGroupVehicles(orgVehicles.filter(v => group.vehicle_id.includes(v.vehicle_id)));
    }
  }, [orgVehicles]);

  // HELPERS

  const postVehicleAddons = async () => {
    let addons = vehicleAddons.map(addon => ({
      type: addon.type,
      assigned_inputs: addon.assigned_inputs,
      alert_level: addon.alert_level,
      addon_meta: {},
    }));

    const { response } = await editVehicleAddons(selectedGroupVehicles, organizationId, addons);

    return !!response;
  };

  const confirmHandler = async () => {
    setSubmitting(true);
    try {
      let success = false;
      success = await postVehicleAddons();

      if (success) {
        dispatchNotification.assignGroupAddonSuccess({ groupName: group.group_name });
        setSubmitting(false);
        setServerError(false);
        navigate(`/groups`);
      }
    } catch (err) {
      logWcpError(err);
      setServerError(true);
      setSubmitting(false);
    }
  };

  const vehicleDropdownOptions =
    orgVehicles
      .sort(sortAscendingAlphaLabel)
      .map(v => ({ label: v.meta.label, value: v.meta.label })) || [];

  return group ? (
    <PageListWrapper>
      <h4>Assign Add-ons </h4>
      {serverError && (
        <ServerErrorDiv>Server error: No message was returned from the server</ServerErrorDiv>
      )}
      <h5>Optional: Choose an existing vehicle to clone</h5>

      <BaseVehicleDiv>
        <FilteredDropdown
          title={'Vehicle'}
          placeholderLabel={'Select a Vehicle'}
          dropdownOptions={vehicleDropdownOptions}
          handleItemSelect={label =>
            setSelectedBaseVehicle(orgVehicles.find(v => v.meta.label === label?.label))
          }
          defaultItem={null}
          clearOnSelection={true}
        />
      </BaseVehicleDiv>
      <VehicleAddonFeatures
        vehicleAddons={vehicleAddons}
        updateVehicleAddons={va => {
          setVehicleAddons(va);
        }}
        preventSubmit={value => {
          setAddonValidationErrors(value);
        }}
        groupAddons
      />
      <SelectAllVehiclesDiv>
        <h5>Vehicles to receive add-ons</h5>
        <SelectAllCheckboxDiv>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={selectedGroupVehicles.length === groupVehicles.length}
              onClick={e => {
                setSelectedGroupVehicles(
                  selectedGroupVehicles.length === groupVehicles.length
                    ? []
                    : [...groupVehicles.map(gv => gv.vehicle_id)],
                );
              }}
            />
          </Checkbox>
          <CheckboxTextSpan>Select All</CheckboxTextSpan>
        </SelectAllCheckboxDiv>
      </SelectAllVehiclesDiv>
      <VehicleListDiv>
        {groupVehicles.map(v => (
          <VehicleListingDiv>
            <Checkbox key={v.vehicle_id} singleCheck>
              <CheckboxInner
                isChecked={selectedGroupVehicles.some(sgvId => sgvId === v.vehicle_id)}
                onClick={e => {
                  selectedGroupVehicles.find(sgvId => sgvId === v.vehicle_id)
                    ? setSelectedGroupVehicles([
                        ...selectedGroupVehicles.filter(id => id !== v.vehicle_id),
                      ])
                    : setSelectedGroupVehicles([...selectedGroupVehicles, v.vehicle_id]);
                }}
              />
            </Checkbox>
            <CheckboxTextSpan>{v.meta.label}</CheckboxTextSpan>
          </VehicleListingDiv>
        ))}
      </VehicleListDiv>
      {groupVehicles.length == 0 && <span> There are no vehicles in {group.group_name}</span>}
      <AssignCancelDiv>
        <Button type="button" onClick={() => navigate(`/groups`)} withRightSpacer mediumAlt default>
          Cancel
        </Button>
        <ButtonWithLoader
          isLoading={submitting}
          confirmText={'Assign'}
          loadingStyleProp={'submittingWithSpinnerModal'}
          notLoadingStyleProp={'mediumAlt'}
          clickHandler={confirmHandler}
          disabled={addonValidationErrors || selectedGroupVehicles.length < 1}
        />
      </AssignCancelDiv>
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  state => ({
    userState: state.user,
    organizationId: state.user.filteredOrganizationId,
  }),
  dispatch => ({
    dispatchNotification: {
      getGroupError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Getting Group', err),
          }),
        ),
      getOrgVehiclesError: ({ err }) =>
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.ERROR,
              'Error Getting Organization Vehicles',
              err,
            ),
          }),
        ),
      assignGroupAddonSuccess: ({ groupName }) =>
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              'Assign Addon Success',
              `Successfully updated selected vehicles in "${groupName}"`,
            ),
          }),
        ),
    },
  }),
)(VehicleGroupAddonsPage);
