import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getGroupById } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useFetchGroupByIdQuery = ({ groupId, enabled }) => {
  const dispatch = useDispatch();
  const [group, setGroup] = useState(null);

  const fetchGroupByIdQuery = useQuery(['fetchGroupByIdQuery'], () => getGroupById({ groupId }), {
    enabled,
    onSuccess: ({ response: { message: group } }) => {
      setGroup(group);
    },
    onError: err => {
      logWcpError(err);
      dispatch(groupFetchError({ err }));
    },
  });

  return [fetchGroupByIdQuery, group];
};
