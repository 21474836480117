import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganization } from 'core/api/organizations';
import { decorateOrganizationObject } from 'core/api/organizations';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { organizationFetchError } from 'features/people/organizations/helpers/notifications';

export const useFetchOrganizationQuery = ({
  enabled = true,
  enableRefetchInterval = false,
} = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(null);
  const fetchOrganizationQuery = useQuery(
    ['fetchOrganizationQuery'],
    () => getOrganization(filteredOrganizationId),
    {
      enabled,
      onSuccess: ({ response: { message: message } }) => {
        let data = message[0];
        setOrganization(decorateOrganizationObject(data));
      },
      onError: err => {
        logWcpError(err);
        dispatch(organizationFetchError({ err }));
      },
    },
  );

  return [fetchOrganizationQuery, organization];
};
