/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependecies
 */
import theme from 'shared/styles/global/theme';
import colors from 'shared/constants/colors';

const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  z-index: 9999;
  width: calc(100% - ${theme.sidebar_width_collapsed});
  height: 100%;
  padding: 51px 83px;
  background: #f9f9f9;
  box-shadow: inset 0 2px 40px 0 rgba(0, 0, 0, 0.09);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  color: #8a909c;
  transition: left 0.3s, width 0.3s;

  ${props =>
    props.pushed &&
    `
		left: ${theme.sidebar_width_expanded};
		width: calc(100% - ${theme.sidebar_width_expanded});
	`}
`;

export const SearchResultsHead = styled.div`
  margin-bottom: 32px;
`;

export const SearchResultsTitle = styled.h4`
  margin-bottom: 15px !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 0.89em;
  letter-spacing: 0.01em;
  color: ${colors.midnight};
`;

export const SearchResultsBody = styled.div``;
export const SearchResultsEntry = styled.div``;
export const SearchResultsText = styled.p``;

export const SearchResultsCount = styled.span`
  color: #8a909c;

  ${props =>
    props.small &&
    `
		color: #8a909c;
		letter-spacing: 0.3px;
	`}
`;

export const SearchResult = styled.div`
  margin-bottom: 31px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SearchResultIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  margin-right: 10px;
`;
export const SearchResultTitle = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #8a909c;

  ${props =>
    props.small &&
    `
		font-size: 14px;
		font-weight: 700;
		color: ${colors.midnight};
		letter-spacing: 0.3px;
	`}
`;

export const SearchResultHead = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);

  ${SearchResultTitle} {
    margin-right: 5px;
  }
`;

export const SearchResultBody = styled.div``;

export const SearchResultItem = styled.div`
  margin-bottom: 14px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
`;

export const SearchResultText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #8a909c;
`;

export default SearchContainer;
