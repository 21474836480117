import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getVehicle } from 'core/api/vehicles';
import { checkLogin } from 'shared/utilities/user';
import { decorateVehicle, sortAscendingAlphaLabel } from 'shared/utilities/vehicle';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';
import {
  mapMaintenanceTotalMilageData,
  vehicleMaintenanceMapping,
} from 'app/features/maintenance/utilities/mapping';
import { vehicleFetchError } from 'features/fleet/vehicles/helpers/notifications';

export const useFetchSingleVehicleQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  vehicleId,
  apiFlags = {
    addons: false,
    cell: true,
    device: true,
    gps: true,
    meta: true,
    maintenance: false,
    obd: false,
  },
  mappingEnabled = false,
} = {}) => {
  const dispatch = useDispatch();
  const [singleVehicle, setVehicle] = useState(null);

  const vehicleQuery = useQuery(
    ['fetchSingleVehicleQuery' + key, vehicleId],
    () => {
      return getVehicle({ vehicleId, apiFlags });
    },
    {
      enabled: autoRun && checkLogin(),
      onSuccess: ({ response: { message: vehicle } }) => {
        let frontendVehicle = null;

        if (mappingEnabled) {
          // TODO create mapping for vehicle entity and replace obsolete decorateVehicle
          frontendVehicle = decorateVehicle(vehicle);

          // just mapping maintenance for now
          frontendVehicle.maintenance = frontendVehicle.maintenance.map(item => {
            let mappedMaintenanceItem = mapToFrontend(item, vehicleMaintenanceMapping);
            mappedMaintenanceItem = mapMaintenanceTotalMilageData({
              maintenanceEvent: mappedMaintenanceItem,
            });
            return mappedMaintenanceItem;
          });
        } else {
          frontendVehicle = decorateVehicle(vehicle);
        }

        setVehicle(frontendVehicle);
      },
      onError: err => dispatch(vehicleFetchError({ err })),
      refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    },
  );

  return [vehicleQuery, singleVehicle];
};
