/**
 * @External dependencies
 */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

/**
 * @Internal dependencies
 */
import { fetchConfigurations, editConfiguration } from 'core/redux/configurations/actions';
import ConfigurationEditModal from 'shared/modals/ConfigurationEditModal';

import useAPIData from 'shared/hooks/useAPIData';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { SectionBody } from 'shared/styles/components/Section';
import { ActionButtonWithIcon, ActionButtonText } from 'shared/styles/components/Button';
import ModalPortal from 'shared/modals/ModalPortal';
import ConfigurationUploadModal from 'shared/modals/ConfigurationUploadModal';
import SortablePaginatedTable from 'shared/ui/table/SortablePaginatedTable';
import TabView from 'shared/ui/tabs/TabView';
import { TableTab, ConfigurationsTableNav } from 'shared/styles/components/Tabs';
import { TableSpinner } from 'shared/styles/components/Spinner';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListTitle,
  PageListHead,
} from 'shared/styles/components/PageList';

import colors from 'shared/constants/colors';
import { permissionData } from 'shared/constants/users';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import MediaWrapper from 'shared/components/MediaWrapper';
import ConfigurationLibraryCardContainer from 'app/features/configurations/mobile/ConfigurationLibraryCardContainer';
import ConfigurationLibraryActionMenu from 'app/features/configurations/menus/ConfigurationLibraryActionMenu';
import { getConfigTypeLabel } from 'app/features/configurations/utilities/configUtilities';
import { sortAscendingAlpha } from 'shared/utilities/general';
import { ConfigurationActionButtonsDiv } from 'shared/styles/components/OtaUpdates';

// TODO - react query
// const [configurations, setConfigurations] = useState(null);
// // gets firmware updates
// const fetchConfigurationsQuery = useQuery(
//   ['fetchConfigurations'],
//   () => getConfigurations(currentOrganizationId),
//   {
//     onSuccess: ({ response: { message: data } }) => {
//       //setConfigurations()
//       // configurations: action.payload.map(configuration => ({
//       //   ...configuration,
//       //   loadingStatus: false,
//       //   deviceCount:
//       //     configuration.supportedDevices?.length !== null &&
//       //     configuration.supportedDevices?.length !== undefined
//       //       ? configuration.supportedDevices?.length
//       //       : 0,
//     },
//     onError: err => dispatch(fetchConfigurationsError({ err })),
//   },
// );

const ConfigurationLibraryPage = ({
  configurations,
  fetchConfigurations,
  filteredOrganizationId,
  editAccess,
}) => {
  const configurationsLoaded = useAPIData({
    fetchAPIData: fetchConfigurations,
    dependencies: [filteredOrganizationId],
  });
  const [uploadModalActive, setUploadModalActive] = useState(false);
  const [editModalActive, setEditModalActive] = useState(false);

  configurations.sort((a, b) => sortAscendingAlpha(a.configName, b.configName)),
    useEffect(() => {
      if (configurations) {
        configurations.forEach(configuration => {
          configuration.formattedTime =
            configuration.uploadedTime !== 'UNKNOWN'
              ? formatISOTimestampToLongDateWithTime(configuration.uploadedTime)
              : configuration.uploadedTime;
        });
      }
    }, [configurations]);

  const [columns] = useState([
    {
      title: 'Configuration Name',
      dataIndex: 'configName',
      key: 'configurationName',
      searchable: true,
      onHeaderCell: () => ({ style: styles.configurationNameHeaderCell }),
      onCell: () => ({ style: styles.configurationNameCell }),
      render(value, row) {
        return editAccess ? (
          <a
            onClick={() => setEditModalActive(row?.orgdata ? row.orgdata : row)}
            style={styles.configurationNameLink}
          >
            <span
              style={styles.vehicleNameAndIcon}
              title={
                'Configuration Name: ' +
                (row?.orgdata?.configName ? row?.orgdata?.configName : value)
              }
            >
              {row.loadingStatus ? (
                <TableSpinner />
              ) : (
                <IconSvgComponent
                  svgFileName={'gears'}
                  alt="Configuration file"
                  svgStyle={styles.rowConfigurationIcon}
                />
              )}

              {value}
            </span>
          </a>
        ) : (
          <span
            style={styles.vehicleNameAndIcon}
            title={
              'Configuration Name: ' + (row?.orgdata?.configName ? row?.orgdata?.configName : value)
            }
          >
            <IconSvgComponent
              svgFileName={'gears'}
              alt="Configuration file"
              svgStyle={styles.rowConfigurationIcon}
            />
            {value}
          </span>
        );
      },
    },

    {
      title: 'Configuration Type',
      dataIndex: 'acmProductId',
      key: 'acmProductId',
      render(value, row) {
        return (
          <span title={'Configuration Type: ' + getConfigTypeLabel(value)}>
            {getConfigTypeLabel(value)}
          </span>
        );
      },
    },

    {
      title: 'Configuration ID',
      dataIndex: 'configId',
      key: 'configurationId',
      searchable: true,
      render(value, row) {
        return (
          <span
            title={'Configuration ID: ' + (row?.orgdata?.configId ? row?.orgdata?.configId : value)}
          >
            {value}
          </span>
        );
      },
    },

    {
      title: 'Uploaded On',
      dataIndex: 'uploadedTime',
      searchIndex: 'formattedTime',
      key: 'uploadedTime',
      searchable: true,
      render(value, row) {
        return <span title={'Uploaded On: ' + row.formattedTime}>{row.formattedTime}</span>;
      },
    },

    {
      title: 'Supported Devices',
      dataIndex: 'deviceCount',
      searchIndex: 'orgdata.deviceCount',
      key: 'supportedDevices',
      searchable: true,
      render(value, row) {
        return <span title={'Supported Devices: ' + value}>{value}</span>;
      },
    },
    {
      title: 'action',
      dataIndex: '',
      className: 'action',
      key: 'action',
      render(row) {
        return <ConfigurationLibraryActionMenu configData={row} />;
      },
    },
  ]);

  return configurationsLoaded ? (
    <PageListWrapper centered={!(configurations && configurations.length > 0)}>
      <PageListHead stackable noBottomMargin>
        <PageListTitle>Configurations</PageListTitle>
        <PageListActionButtonContainer>
          <ConfigurationActionButtonsDiv>
            <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
              <ActionButtonWithIcon create onClick={() => setUploadModalActive(!uploadModalActive)}>
                <ActionButtonText>Upload Configuration</ActionButtonText>
                <IconSvgComponent
                  svgFileName={'arrow-up'}
                  alt="Upload"
                  style={styles.uploadButtonIcon}
                />
              </ActionButtonWithIcon>
            </PermissionProtectedElement>
          </ConfigurationActionButtonsDiv>
        </PageListActionButtonContainer>
      </PageListHead>
      {configurations && configurations.length > 0 ? (
        <React.Fragment>
          <SectionBody>
            <TabView
              tabContainer={ConfigurationsTableNav}
              tabs={[
                {
                  tabElement: <TableTab style={{ flex: '0 1 25%' }}>Library</TableTab>,
                  content: (
                    <MediaWrapper
                      mobileComponent={
                        <ConfigurationLibraryCardContainer
                          configurationList={configurations.filter(
                            configuration => configuration.archived === 'false',
                          )}
                        />
                      }
                      nonMobileComponent={
                        <SortablePaginatedTable
                          className="table-simple"
                          tabChange="Library"
                          columns={columns}
                          tableData={configurations.filter(
                            configuration => configuration.archived === 'false',
                          )}
                          rowKey={record => record.configId}
                          scroll={{ x: false, y: false }}
                        />
                      }
                    />
                  ),
                },
                {
                  tabElement: <TableTab style={{ flex: '0 1 25%' }}>Archive</TableTab>,
                  content: (
                    <MediaWrapper
                      mobileComponent={
                        <ConfigurationLibraryCardContainer
                          configurationList={configurations.filter(
                            configuration => configuration.archived === 'true',
                          )}
                        />
                      }
                      nonMobileComponent={
                        <SortablePaginatedTable
                          className="table-simple"
                          tabChange="Archive"
                          columns={columns}
                          tableData={configurations.filter(
                            configuration => configuration.archived === 'true',
                          )}
                          rowKey={record => record.configId}
                          scroll={{ x: false, y: false }}
                        />
                      }
                    />
                  ),
                },
              ]}
            />
          </SectionBody>
        </React.Fragment>
      ) : (
        <SectionBody>
          {editAccess ? (
            <React.Fragment>
              <IconSvgComponent svgFileName="cloud-large" alt="Cloud icon" />
              <h4>Upload your first configuration</h4>
              <ActionButtonWithIcon
                onClick={() => {
                  setUploadModalActive(!uploadModalActive);
                }}
              >
                <ActionButtonText>Upload Configuration</ActionButtonText>
                <IconSvgComponent
                  svgFileName={'arrow-up'}
                  alt="Upload"
                  style={styles.uploadButtonIcon}
                />
              </ActionButtonWithIcon>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IconSvgComponent
                svgStyle={styles.splashIcon}
                svgFileName={'gears-grey'}
                alt="Configurations"
                beforeInjection={svg => {
                  svg.setAttribute('width', 187);
                  svg.setAttribute('height', 187);
                  svg
                    .querySelectorAll('path')
                    .forEach(path => path.setAttribute('fill', colors.midnight));
                }}
              />
              <h4>No Configurations Available</h4>
            </React.Fragment>
          )}
        </SectionBody>
      )}
      {uploadModalActive ? (
        <ModalPortal onRequestClose={() => setUploadModalActive(false)}>
          <ConfigurationUploadModal onSuccess={() => setUploadModalActive(false)} />
        </ModalPortal>
      ) : null}
      {editModalActive ? (
        <ModalPortal onRequestClose={() => setEditModalActive(false)}>
          <ConfigurationEditModal
            data={editModalActive}
            onSuccess={() => setEditModalActive(false)}
          />
        </ModalPortal>
      ) : null}
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

const styles = {
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tableTitle: {
    display: 'inline-block',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '10px 15px 10px 18px',
  },
  uploadButtonIcon: {
    marginBottom: '0px',
    marginLeft: '11px',
    width: '10px',
  },
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  rowConfigurationIcon: {
    marginRight: 10,
    fontSize: 0,
    width: '24px',
  },
  configurationNameHeaderCell: {
    paddingLeft: 0,
  },
  configurationNameCell: {
    alignItems: 'center',
    color: colors.midnight,
    fontSize: 14,
    paddingLeft: 0,
  },
  configurationNameLink: {
    textDecoration: 'none',
  },
  configurationIDCell: {
    color: colors.midnight,
  },
  deviceTooltipList: {
    listStyle: 'none',
  },
};

export default connect(
  state => ({
    configurations: state.configurations.configurations,
    filteredOrganizationId: state.user.filteredOrganizationId,
    editAccess: checkForUserPermission(state.user, permissionData.editotaconfig),
  }),
  dispatch => ({
    fetchConfigurations: () => dispatch(fetchConfigurations()),
  }),
)(ConfigurationLibraryPage);
