import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createVehicle } from 'core/api/vehicles';
import React from 'react';

const useCreateVehicleMutation = () => {
  const queryClient = useQueryClient();
  const createVehicleMutation = useMutation(
    ['createVehicleMutation'],
    vehicle => createVehicle(vehicle),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['fetchVehiclesQuery']);
      },
    },
  );

  return createVehicleMutation;
};

export default useCreateVehicleMutation;
