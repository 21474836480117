/**
 * @ External Dependencies
 */
import React, { Component } from 'react';

/**
 * @ Components
 */
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

/**
 * @ Styled Components
 */
import { SimpleDropdownWrapper, SimpleDropdown } from 'shared/styles/components/DropdownMenu';

/**
 * Class for simple dropdown.
 *
 * @class      SimpleDropdown (name)
 */
class SimpleDropdownComponent extends Component {
  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const {
      active,
      vehicleId,
      toggleEditModal,
      toggleDeleteModal,
      onClick,
      children,
      innerRef,
      toggleRef,
      disabled,
    } = this.props;

    return (
      <SimpleDropdownWrapper innerRef={innerRef}>
        <a
          style={styles.dropdownToggle}
          disabled={disabled}
          className={active ? 'active' : ''}
          href="#"
          onClick={onClick}
          ref={toggleRef}
          title="More options"
        >
          <IconSvgComponent svgFileName={'dots'} alt="More options" title="More options" />
        </a>

        {active ? <SimpleDropdown>{this.props.children}</SimpleDropdown> : null}
      </SimpleDropdownWrapper>
    );
  }
}

export default SimpleDropdownComponent;

const styles = {
  dropdownToggle: {
    backgroundColor: 'transparent',
    width: 20,
    height: 30,
    border: 'none',
  },
};
