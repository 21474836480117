import { pointsToHeading } from 'app/features/playback/services/Utilities/pointsToHeading';

export const directionalPoints = points => {
  if (!points?.length) {
    return [];
  }

  function createMidwayPoint(previousPoint, currentPoint) {
    const heading = pointsToHeading(
      previousPoint.lat,
      previousPoint.long,
      currentPoint.lat,
      currentPoint.long,
    );
    return {
      heading: heading,
      long: (previousPoint.long + currentPoint.long) / 2,
      lat: (previousPoint.lat + currentPoint.lat) / 2,
      id: `midway-${new Date().toISOString()}`,
    };
  }

  let filteredPoints = [];
  filteredPoints[0] = {
    status: points[0].status,
    midwayPoints: [
      {
        heading: points[0].heading,
        lat: points[0].lat,
        long: points[0].long,
        id: `midway-${new Date().toISOString()}`,
      },
    ],
  };
  let count = 0;

  for (let i = 1; i < points.length; i++) {
    const currentPoint = points[i];
    const previousPoint = points[i - 1];
    const currentTimeISO = new Date(currentPoint.time).toISOString();
    const previousTimeISO = new Date(previousPoint.time).toISOString();

    const timeDifference = (new Date(currentTimeISO) - new Date(previousTimeISO)) / 1000; // Convert to seconds
    if (timeDifference > 60) {
      count++;
      filteredPoints[count] = {
        status: currentPoint.status,
        midwayPoints: [],
      };
    } else if (currentPoint.status !== previousPoint.status) {
      filteredPoints[count].midwayPoints.push(createMidwayPoint(previousPoint, currentPoint));
      count++;
      filteredPoints[count] = {
        status: currentPoint.status,
        midwayPoints: [],
      };
    } else if (timeDifference < 60) {
      filteredPoints[count].midwayPoints.push(createMidwayPoint(previousPoint, currentPoint));
    }
  }

  return filteredPoints;
};
