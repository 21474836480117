import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { postMaintenanceApi } from 'app/features/maintenance/api/post/postMaintenanceApi';
import {
  maintenancePostError,
  maintenancePostPartialSuccess,
  maintenancePostSuccess,
} from 'app/features/maintenance/utilities/notifications';
import { mapAndMassageMaintenanceData } from 'app/features/maintenance/utilities/mapping';

const useCreateMaintenanceEventsMutation = () => {
  const queryClient = useQueryClient();
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const createMaintenanceEventsMutation = useMutation(
    ['createMaintenanceEventsMutation'],
    ({ vehicleIds, maintenanceEvent }) => {
      return postMaintenanceApi({
        vehicleIds,
        maintenanceEvent,
        organizationId: filteredOrganizationId,
      });
    },
    {
      onSuccess: ({ data, status }) => {
        // should always be 207 in this case
        if (status !== 207) throw new Error('Unexpected response status code');

        // get status code for each message in the response
        const statusCodes = data.message.map(ir => ir.statusCode);

        // check if all status codes are 400 or if none are 400
        const allErrors = statusCodes.every(code => code === 400);
        const noErrors = !statusCodes.some(code => code === 400);

        if (allErrors) {
          dispatch(maintenancePostError({ err: data.message }));
        } else if (noErrors) {
          dispatch(maintenancePostSuccess());
        } else {
          dispatch(maintenancePostPartialSuccess({ msg: data.message }));
        }
      },
    },
  );

  return createMaintenanceEventsMutation;
};

export default useCreateMaintenanceEventsMutation;
