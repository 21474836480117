/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import Table from 'rc-table';
import device from 'shared/constants/sizing';

/**
 * @ Internal Dependencies
 */
import colors from 'shared/constants/colors';

export default styled.table`
  font-size: 14px;
  color: #8a909c;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  .checkbox {
    margin-right: 16px;
  }

  tr {
    &:last-child td {
      border-bottom: 0 none;
    }

    &:hover {
      background: none;
    }
  }

  th {
    background: none;
    padding: 0 0 7px 0;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: #8a909c;
  }

  td {
    padding: 31px 0 25px;
    border-bottom: 1px solid rgba(138, 144, 156, 0.15);

    .svg-icon {
      margin-right: 12px;
    }

    &.name {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.midnight};
    }

    span.success {
      color: ${colors.midnight};
    }

    span.danger {
      color: ${colors.cherry};
    }
  }

  .with-icon {
    .svg-icon {
      margin-right: 0;
      margin-left: 8px;
    }

    &.warning {
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

export const DeviceCell = styled.div`
  padding: 14px 0;
  font-family: Muli, sans-serif;
  font-size: 14px;
  color: ${colors.haze};

  ${props =>
    props.tableHorizontalPadding &&
    props.nameCell &&
    `
    padding-left: ${props.tableHorizontalPadding}px;
    color: ${colors.midnight};
    font-size: 14px;
    font-weight: 700;
    font-family: Muli, sans-serif;
  `}

${props =>
  props.firmwareVersion &&
  `
    padding-left: ${props.tableHorizontalPadding}px;
    color: ${colors.haze};
    font-size: 14px;
    font-weight: 400;
    font-family: Muli, sans-serif;
  `}


  ${props =>
    props.maxWidth &&
    props.tableHorizontalPadding &&
    props.nameCell &&
    `
    max-width: calc(${props.maxWidth}px - ${props.tableHorizontalPadding}px);
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;

export const SupportedDevicesTable = styled.table`
  table-layout: fixed;
  margin-top: 16px;
`;

export const deviceOuterCellStyles = {
  padding: '0px',
};

export const supportedDevicesHeaderCellStyles = {
  backgroundColor: `${colors.fog}`,
  fontFamily: 'Muli, sans-serif',
  fontSize: '10px',
  textTransform: 'uppercase',
  textAlign: 'left',
};

export const DeviceTable = styled(Table)`
  ${props =>
    props.tablePaddingLeft &&
    props.tablePaddingRight &&
    `
    margin-left: -${props.tablePaddingLeft}px;
    margin-right: -${props.tablePaddingRight}px;
  `} margin-bottom: 40px;
`;

export const GroupNameCell = styled.span`
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const SortArrowSpan = styled.span`
  margin-left: 10px;
  opacity: 0;
  ${props =>
    props.isSorted &&
    `
    opacity: 1;
	`};
`;

export const TableFilterSpan = styled.span`
  margin-left: 5px;
  font-size: 22px;
  @media ${device.large} {
    font-size: 16px;
  }
`;

export const SelectWrapper = styled.div`
  padding-left: 5px;
  padding-top: 15px;
`;

export const PaginationSelectWrapper = styled.div`
  margin-top: 10px;
  width: 50px;
  margin-left: -3px;
  margin-right: -3px;
`;

export const TableCellCheckboxWrapper = styled.span`
  margin-right: 15px;
  padding-top: 3px;
`;
export const SmallTableWrapper = styled.div`
  max-width: 800px;
`;
