import React, { useState, useEffect } from 'react';
import Table from 'rc-table';
import { SORT_ORDER } from 'shared/constants/table';
import { paginatedTableColumnSort, reactStringReplace } from 'shared/utilities/table';
import { SortArrowSpan, PaginationSelectWrapper } from 'shared/styles/components/Table';
import ReactPaginate from 'react-paginate';
import { PaginationStyles, TableFilters } from 'shared/styles/components/PageList';
import { PAGINATION_OPTIONS } from 'shared/constants/pagination';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { PAGINATION_DEFAULT_OPTION, PAGINATION_SMALL_OPTION } from 'shared/constants/pagination';
import colors from 'shared/constants/colors';
import Searchbox from 'shared/components/Searchbox';

// custom header cell component that wraps/overwrites default table header cell (see: Table -> components prop line 56)
const SortableHeaderCell = ({ children, isSorted, sortOrder, sortIndex, style, handleSort }) => {
  const onClick = sortIndex && (() => handleSort(sortIndex));
  const sortArrow =
    sortOrder === SORT_ORDER.ASC ? String.fromCharCode(9650) : String.fromCharCode(9660);

  return (
    <th onClick={onClick} style={style}>
      {children}
      <SortArrowSpan isSorted={isSorted}>{sortArrow}</SortArrowSpan>
    </th>
  );
};

const SortablePaginatedTable = ({
  style,
  className,
  rowClassName,
  columns,
  tableData,
  onRow,
  rowKey,
  scroll,
  children,
  components,
  emptyText,
  tabChange,
  defaultSortOrder,
  setListingCount,
  smallTable,
  matchingColumns,
  setMatchingColumns,
}) => {
  const [transformedData, updateTransformedData] = useState({
    tableData,
    index: null,
    order: null,
    isSorted: false,
  });

  const [tableDataWithSearchApplied, setTableDataWithSearchApplied] = useState(null);

  const [pageSize, setPageSize] = useState(
    smallTable ? PAGINATION_SMALL_OPTION : PAGINATION_DEFAULT_OPTION,
  );
  const [currentPageData, setCurrentPageData] = useState(tableData.slice(0, pageSize.value));
  const [currentPageNum, setCurrentPageNum] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(
    Math.ceil(tableData.length / pageSize.value),
  );
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setTotalPageCount(Math.ceil(transformedData.tableData.length / pageSize.value));
    handlePaginate({ selected: currentPageNum });
  }, [transformedData]);

  useEffect(() => {
    handlePaginate({ selected: 0 });
  }, [tabChange]);

  useEffect(() => {
    updateTransformedData(tableDataWithSearchApplied);
  }, [tableDataWithSearchApplied]);

  const handleSort = sortIndex => {
    const { tableData, order, index } = transformedData;
    let sortOrder;

    if (sortIndex === index) {
      // if we are clicking on a column thats already been sorted, we want to sort it using the reverse order
      sortOrder = order === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC;
    } else {
      sortOrder = defaultSortOrder ? defaultSortOrder : SORT_ORDER.ASC;
    }

    const newTransformedData = paginatedTableColumnSort({
      data: tableData,
      sortIndex,
      order: sortOrder,
    });

    updateTransformedData({
      tableData: newTransformedData,
      order: sortOrder,
      index: sortIndex,
      isSorted: true,
    });
  };

  const handlePaginate = pageData => {
    let pNum = pageData.selected;
    let pSize = pageData.pageSize?.value ? pageData.pageSize?.value : pageSize.value;
    let dataSource = tableDataWithSearchApplied ? tableDataWithSearchApplied : transformedData;
    let items = dataSource.tableData.slice(pNum * pSize, pNum * pSize + pSize);
    if (!items.length && pNum) {
      items = dataSource.tableData.slice((pNum - 1) * pSize, (pNum - 1) * pSize + pSize);
      pNum = pNum - 1;
    }

    setCurrentPageData(items);
    setCurrentPageNum(pNum);
  };

  useEffect(() => {
    let currTransformedData = { ...transformedData };
    if (!searchText) {
      const newTransformedData = paginatedTableColumnSort({
        data: tableData,
        sortIndex: currTransformedData.index,
        order: currTransformedData.order,
      });
      currTransformedData.tableData = newTransformedData;
      updateTransformedData(currTransformedData);
    }
    const searchValue = searchText?.toLowerCase();
    if (searchValue !== '') {
      const filteredData = tableData
        .map(row => {
          let fieldData = { ...row };
          fieldData['orgdata'] = { ...row };
          let flag = false;
          columns.forEach(col => {
            if (col.searchable === true && col.dataIndex) {
              let indexedValue = getIndexedColumnValue(
                fieldData,
                col.searchIndex ? col.searchIndex : col.dataIndex,
              )?.toString();
              if (indexedValue) {
                indexedValue = indexedValue.toString();
              }
              if (indexedValue && indexedValue?.toLowerCase()?.includes(searchValue)) {
                flag = true;
                const highlightedData = reactStringReplace(
                  indexedValue,
                  searchText,
                  (match, fieldData) => (
                    <span key={fieldData} style={{ fontWeight: 'bolder', color: colors.midnight }}>
                      {match}
                    </span>
                  ),
                );
                fieldData = replaceByHighlightedData(fieldData, col, highlightedData);
                //add some sort of prop that tells the table this is the field we found
                if (setMatchingColumns) {
                  let matchingColumnsToSet = matchingColumns;
                  matchingColumnsToSet
                    ?.filter(mc => mc.vehicle_id === fieldData.vehicle_id)
                    .forEach(mc => (mc.column = col.dataIndex));

                  setMatchingColumns(matchingColumnsToSet);
                }
              }
            }
          });

          if (flag) return fieldData;
        })
        .filter(Boolean);
      currTransformedData.tableData = filteredData;
    } else {
      currTransformedData.tableData = tableData;
    }

    /*  if (e.page_number == true) {
        setCurrentPageNum(currentPageNum);
      } else {
        setCurrentPageNum(0);
      } */

    setTableDataWithSearchApplied(currTransformedData);
    setListingCount && setListingCount(currTransformedData.tableData.length);
  }, [searchText, tableData]);

  const getIndexedColumnValue = (row, dataIndex) => {
    dataIndex = dataIndex.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    dataIndex = dataIndex.replace(/^\./, ''); // strip a leading dot
    const formattedDataIndex = dataIndex.split('.');
    for (let i = 0; i < formattedDataIndex.length; ++i) {
      const col = formattedDataIndex[i];
      if (col in row) {
        row = row[col];
      } else {
        return;
      }
    }
    return row;
  };

  const replaceByHighlightedData = (fieldData, col, highlightedData) => {
    if (!col.searchIndex) {
      fieldData[col.dataIndex] = highlightedData;
    } else {
      if (col.searchIndex.includes('.')) {
        const levels = col.searchIndex.split('.');
        fieldData[levels[0]][levels[1]] = highlightedData;
      } else {
        fieldData[col.searchIndex] = highlightedData;
      }
    }
    return fieldData;
  };

  const configureCustomHeader = column => {
    return {
      ...column,
      onHeaderCell: record => {
        const prevOnHeaderCell = column.onHeaderCell ? column.onHeaderCell(record) : {};
        if (!column.dataIndex) return prevOnHeaderCell;

        const style = prevOnHeaderCell.style
          ? { ...prevOnHeaderCell.style, cursor: 'pointer' }
          : { cursor: 'pointer' };

        return {
          ...prevOnHeaderCell,
          style,
          handleSort,
          sortIndex: record.dataIndex,
          isSorted: record.dataIndex === transformedData.index,
          sortOrder: transformedData.order,
        };
      },
    };
  };

  const updatePageSize = e => {
    setPageSize(e);
    setTotalPageCount(Math.ceil(transformedData.tableData.length / e.value));
    handlePaginate({ selected: 0, pageSize: e });
  };

  // override columns and use existing/mandatory column props to pass in a sortIndex value and local handleSort function
  const sortableColumns = (() => {
    // if child column components are passed convert them to columns
    if (children) {
      return React.Children.map(
        children,
        child =>
          child &&
          Object.prototype.hasOwnProperty.call(child.props, 'title') &&
          Object.prototype.hasOwnProperty.call(child.props, 'dataIndex') &&
          configureCustomHeader(child.props),
      );
    }
    return columns.map(column => configureCustomHeader(column));
  })();

  useEffect(() => {
    const { index, order } = transformedData;

    let td = paginatedTableColumnSort({ data: tableData, sortIndex: index, order });

    updateTransformedData({
      ...transformedData,
      tableData: td,
    });

    //filterChangeHandler({ search_key: searchText, page_number: true });
  }, [tableData]);

  useEffect(() => {
    // default sort by first column
    if (!transformedData.isSorted) {
      const defaultSortIndex = sortableColumns.find(c => c && c.dataIndex && c.title)?.dataIndex;
      handleSort(defaultSortIndex);
    }
  }, [transformedData.isSorted]);

  return (
    <PaginationStyles>
      <TableFilters>
        <Searchbox smallSearch={false} setSearchText={setSearchText} searchText={searchText} />
        <div className="pagination">
          <div className="page-size-dd">
            <div className="show-label">show</div>
            <PaginationSelectWrapper>
              <StyledReactSelect
                defaultValue={{ label: pageSize.value }}
                isClearable={false}
                isSearchable={false}
                options={PAGINATION_OPTIONS}
                onChange={updatePageSize}
              />
            </PaginationSelectWrapper>
          </div>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPageNum}
            nextClassName={!currentPageData.length ? 'disabled next-page' : 'next-page'}
          />
        </div>
      </TableFilters>
      <Table
        style={style}
        className={className}
        rowClassName={rowClassName}
        columns={sortableColumns}
        data={currentPageData}
        onRow={onRow}
        rowKey={rowKey}
        scroll={scroll}
        emptyText={searchText ? 'No records match the current search criteria' : emptyText}
        components={{
          ...components,
          header: {
            ...(components && components.header),
            ...{ cell: SortableHeaderCell },
          },
        }}
      />
      <TableFilters>
        <div />
        <div className="pagination">
          <div className="page-size-dd">
            <div className="show-label">show</div>
            <PaginationSelectWrapper>
              <StyledReactSelect
                value={{ label: pageSize.value }}
                isClearable={false}
                isSearchable={false}
                options={PAGINATION_OPTIONS}
                onChange={updatePageSize}
              />
            </PaginationSelectWrapper>
          </div>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={currentPageNum}
            nextClassName={!currentPageData.length ? 'disabled next-page' : 'next-page'}
          />
        </div>
      </TableFilters>
    </PaginationStyles>
  );
};

export default SortablePaginatedTable;
