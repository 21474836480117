import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ViewMaintenanceLogsMenuItem from 'app/features/maintenance/components/MaintenanceSummary/menus/ViewMaintenanceLogsMenuItem';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import ProtectedElement from 'core/components/authorization/ProtectedElement';
import VehicleList from 'features/fleet/vehicles/components/VehicleList';
import { useFetchVehiclesQuery } from 'features/fleet/vehicles/hooks/useFetchVehiclesQuery';
import {
  allAdminUserRoles,
  FIELD_SOLUTION_ENGINEER,
  FLEET_ANALYST,
  ORGANIZATION_ADMIN,
  permissionData,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import { ActionButtonText, ActionButtonWithIcon } from 'shared/styles/components/Button';
import { DropdownLink, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import { PageListActionButtonContainer, PageListTitle } from 'shared/styles/components/PageList';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SimpleMenuItem from 'shared/ui/menus/SimpleMenuItem';
import ToggleLockVehicleMenuItem from 'shared/ui/menus/ToggleLockVehicleMenuItem';
import ViewLogsMenuItem from 'shared/ui/menus/ViewLogsMenuItem';
import ViewOnMapMenuItem from 'shared/ui/menus/ViewOnMapMenuItem';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import ActionMenu from 'shared/ui/table/ActionMenu';
import ToggleLockWidget from 'shared/widgets/ToggleLockWidget';
import VehicleListExportButton from 'app/features/vehicles/buttons/VehicleListExportButton';

const VehicleListingPage = () => {
  const isHomeOrg = useSelector(
    state => state.user.user.organizationId === state.user.filteredOrganizationId,
  );
  const organizations = useSelector(state => state.organizations.organizations);
  const organization = useSelector(state =>
    state.organizations.organizations.find(
      o => o.organizationId === state.user.filteredOrganizationId,
    ),
  );

  const [vehiclesQuery, vehicles] = useFetchVehiclesQuery({
    apiFlags: {
      addons: false,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  // temporary solution: this is not a good way - will fail if there are no orgs in DB .
  const finishedLoadingOrgs = organizations.length > 0;

  return (
    <>
      {!finishedLoadingOrgs || vehiclesQuery.isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {vehiclesQuery.isFetching && <LoadingOverlay />}
          <VehicleList
            vehicles={vehicles}
            vehiclesFetching={vehiclesQuery.isFetching}
            leftHeaderComponent={<PageListTitle>Vehicles</PageListTitle>}
            rightHeaderComponent={({ commands }) => (
              <PageListActionButtonContainer>
                {isHomeOrg ? <ToggleLockWidget vehiclesOrVehicle={vehicles} /> : null}
                <VehicleListExportButton vehicles={vehicles} />
                <Link
                  to={{
                    pathname: `/createvehicle`,
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <ActionButtonWithIcon create>
                    <IconSvgComponent svgFileName="plus-simple" alt="Create" />

                    <ActionButtonText>Create Vehicle </ActionButtonText>
                  </ActionButtonWithIcon>
                </Link>
              </PageListActionButtonContainer>
            )}
            actionMenuComponent={(vehicle, commands) => {
              return (
                <ActionMenu>
                  <SimpleDropdownItem>
                    <DropdownLink to={`/vehicle/${encodeURIComponent(vehicle.vehicle_id)}`}>
                      Edit Vehicle
                    </DropdownLink>
                  </SimpleDropdownItem>
                  {isHomeOrg ? (
                    <ToggleLockVehicleMenuItem
                      vehicle={vehicle}
                      onClick={() => commands.showToggleLockModal(vehicle)}
                    />
                  ) : null}

                  <PermissionProtectedElement requiredPermissions={[permissionData.viewmap]}>
                    <SimpleDropdownItem>
                      <ViewOnMapMenuItem vehicle={vehicle} />
                    </SimpleDropdownItem>
                  </PermissionProtectedElement>

                  <PermissionProtectedElement
                    requiredPermissions={[permissionData.editfleet, permissionData.viewanalytics]}
                  >
                    <SimpleDropdownItem>
                      <ViewLogsMenuItem vehicle={vehicle} />
                    </SimpleDropdownItem>
                  </PermissionProtectedElement>
                  <SimpleDropdownItem>
                    <ViewMaintenanceLogsMenuItem vehicleId={vehicle.vehicle_id} />
                  </SimpleDropdownItem>
                  {/* Regardless of customizable permissions, roles outside of the following roles should never be able to reset a vehicle */}
                  <ProtectedElement
                    allowedRoles={[
                      userRoles[FIELD_SOLUTION_ENGINEER].value,
                      userRoles[SYSTEM_ADMIN].value,
                      userRoles[FLEET_ANALYST].value,
                      userRoles[ORGANIZATION_ADMIN].value,
                    ]}
                  >
                    <SimpleMenuItem
                      title={'Reset'}
                      onClick={() => commands.showResetModal(vehicle)}
                    />
                  </ProtectedElement>

                  <ProtectedElement allowedRoles={allAdminUserRoles}>
                    <SimpleMenuItem
                      title={'Delete'}
                      onClick={() => commands.showDeleteModal(vehicle)}
                    />
                  </ProtectedElement>
                </ActionMenu>
              );
            }}
          ></VehicleList>
        </>
      )}
    </>
  );
};

export default VehicleListingPage;
